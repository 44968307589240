import { z } from 'zod';
import { DiagnosisImageSets, DiagnosisOptionSets, DiagnosisValue, ImageDiagnosisValues } from '../ImageDiagnosis';
import { RadioOptionSchema } from '../RadioGroupWithOther';
import { InputSuffixOptionSchema, InputWithSuffixValue } from './dynamicFields/TextWithSuffixField';
import { typeConverters } from './typeConverters';

// I'm using zod (https://zod.dev/) to define the schema for the dynamic form fields because Typescript lacks the
// ability to do runtime type checking.  The fields array contains multiple types (text, checkbox, radio, etc.) and
// this will allow me to validate the fields before rendering them keeping things type safe.

export const FIELD_TYPE = {
  text: 'text',
  textWithSuffix: 'textWithSuffix',
  checkbox: 'checkbox',
  checkboxGroup: 'checkboxGroup',
  radio: 'radio',
  shortRange: 'shortRange',
  imageDiagnosis: 'imageDiagnosis',
  markdown: 'markdown',
  painSelector: 'painSelector'
} as const;
type ObjectValues<T> = T[keyof T];
export type FieldType = ObjectValues<typeof FIELD_TYPE>;

// zodEnum is a helper function that allows us to define a zod enum from an array of values.  In practice this looks
// like: z.enum(zodEnum(['value1', 'value2', 'value3']))
const zodEnum = <T>(arr: T[]): [T, ...T[]] => arr as [T, ...T[]];

const BasicFieldSchema = z.object({
  // name is the name of the field
  name: z.string(),

  // label is the text that will be displayed next to (in the case of a checkbox) or above the field
  label: z.string(),

  // description is an optional field that can be used to provide additional context for the field
  description: z.string().optional(),

  // some field types do not have a default value, so we default it to undefined
  defaultValue: z.undefined(),

  // validators is an optional array of simple validators that can be applied to the field.  These are classified as
  // "simple" validators because they do not have any extra configuration options.
  validators: z.string().array().optional()
});

export const DynamicTypedBasicFieldSchema = BasicFieldSchema.extend({
  // fieldType is mandatory property that's used to specify the type of the value (i.e. the output value).  This is
  // used on fields might return different types (e.g. a text field might return a string or number).
  fieldType: z.enum(zodEnum(Object.keys(typeConverters)))
});

export const DynamicTypedBasicWithMinMaxFieldSchema = DynamicTypedBasicFieldSchema.extend({
  minValue: z.number().optional(),
  maxValue: z.number().optional()
});

const RadioSimpleValueSchema = z.union([z.string(), z.number(), z.boolean(), z.null()]);
export const RadioWithOtherValuesSchema = z
  .object({
    value: RadioSimpleValueSchema
  })
  .and(z.record(z.string(), RadioSimpleValueSchema));
export type RadioWithOtherValue = z.infer<typeof RadioWithOtherValuesSchema>;

export const RadioValueSchema = z.union([RadioSimpleValueSchema, RadioWithOtherValuesSchema]);
export type RadioValue = z.infer<typeof RadioValueSchema>;

// The following field schema definitions should contain a "type" param with a literal string value of the type (e.g.
// "text", "checkbox", "radio")

export const TextFieldSchema = DynamicTypedBasicWithMinMaxFieldSchema.extend({
  type: z.literal(FIELD_TYPE.text),
  defaultValue: z.string().optional()
});
export type TextField = z.infer<typeof TextFieldSchema>;

export const TextWithSuffixFieldSchema = DynamicTypedBasicWithMinMaxFieldSchema.extend({
  type: z.literal(FIELD_TYPE.textWithSuffix),
  defaultValue: z.string().optional(),
  suffixDefaultValue: z.string().optional(),
  suffixOptions: z.array(InputSuffixOptionSchema)
});
export type TextWithSuffixField = z.infer<typeof TextWithSuffixFieldSchema>;

export const CheckboxFieldSchema = BasicFieldSchema.extend({
  type: z.literal(FIELD_TYPE.checkbox),
  defaultValue: z.boolean().optional()
});
export type CheckboxField = z.infer<typeof CheckboxFieldSchema>;

export const CheckboxGroupOptionSchema = z.object({
  label: z.string(),
  value: z.string(),
  enableOtherText: z.boolean().optional(),
  otherOptionPlaceholder: z.string().optional()
});
export type CheckboxGroupOption = z.infer<typeof CheckboxGroupOptionSchema>;

export const CheckboxGroupFieldSchema = BasicFieldSchema.extend({
  type: z.literal(FIELD_TYPE.checkboxGroup),
  options: z.array(CheckboxGroupOptionSchema),
  expandedRadioOptions: z.array(RadioOptionSchema).optional()
});
export type CheckboxGroupField = z.infer<typeof CheckboxGroupFieldSchema>;

export const CheckboxGroupValueSchema = z.object({
  checked: z.boolean(),
  otherText: z.string().optional(),
  radioValue: RadioValueSchema.optional()
});
export type CheckboxGroupValue = z.infer<typeof CheckboxGroupValueSchema>;
export const CheckboxGroupValuesSchema = z.record(z.string(), CheckboxGroupValueSchema);
export type CheckboxGroupValues = z.infer<typeof CheckboxGroupValuesSchema>;

export const RadioFieldSchema = DynamicTypedBasicFieldSchema.extend({
  type: z.literal(FIELD_TYPE.radio),
  defaultValue: z.string().optional(),
  options: z.array(RadioOptionSchema),
  otherOption: RadioOptionSchema.optional(),
  otherOptionInputName: z.string().optional(),
  otherOptionDefaultValue: z.string().optional(),
  otherOptionPlaceholder: z.string().optional()
}).refine((data) => {
  if (data.otherOption && !data.otherOptionInputName) {
    throw new Error('otherOptionInputName is required when otherOption is provided');
  }
  return true;
});
export type RadioField = z.infer<typeof RadioFieldSchema>;

export const ImageDiagnosisFieldSchema = DynamicTypedBasicFieldSchema.extend({
  type: z.literal(FIELD_TYPE.imageDiagnosis),
  defaultOptionValue: z.string().or(z.number()).optional(),

  // Additional diagnosis image and option sets can be added in diagnosisImages/index.ts and diagnosisOptions/index.ts
  // files in the ImageDiagnosis component directory.
  diagnosisImageSet: z.enum(zodEnum(Object.keys(DiagnosisImageSets))),
  diagnosisOptionSet: z.enum(zodEnum(Object.keys(DiagnosisOptionSets)))
});
export type ImageDiagnosisField = z.infer<typeof ImageDiagnosisFieldSchema>;

export const ShortRangeFieldSchema = DynamicTypedBasicFieldSchema.extend({
  type: z.literal(FIELD_TYPE.shortRange),
  defaultValue: z.number().optional(),
  min: z.number().min(0).max(9),
  max: z.number().min(1).max(10),
  minLabel: z.string(),
  maxLabel: z.string()
});
export type ShortRangeField = z.infer<typeof ShortRangeFieldSchema>;

export const MarkdownFieldSchema = BasicFieldSchema.extend({
  type: z.literal(FIELD_TYPE.markdown),
  markdown: z.string()
});
export type MarkdownField = z.infer<typeof MarkdownFieldSchema>;

export const PainSelectorFieldSchema = DynamicTypedBasicFieldSchema.extend({
  type: z.literal(FIELD_TYPE.painSelector),
  defaultOptionValue: z.string().or(z.number()).optional(),
  diagnosisOptionSet: z.enum(zodEnum(Object.keys(DiagnosisOptionSets)))
});
export type PainSelectorField = z.infer<typeof PainSelectorFieldSchema>;

const FieldSchema = z.union([
  TextFieldSchema,
  TextWithSuffixFieldSchema,
  CheckboxFieldSchema,
  CheckboxGroupFieldSchema,
  RadioFieldSchema,
  ShortRangeFieldSchema,
  ImageDiagnosisFieldSchema,
  MarkdownFieldSchema,
  PainSelectorFieldSchema
]);
export type Field = z.infer<typeof FieldSchema>;

// TODO: There are more parts of the top level schema that need to be defined (like the form's name, maybe a
//  description, etc).  This is just a start with the fields array.
export const DynamicFormSchema = z.object({
  fields: z.array(FieldSchema)
});

export type Answers = Record<string, unknown>;

export type TypedAnswer =
  | string
  | number
  | boolean
  | undefined
  | null
  | string[]
  | CheckboxGroupValues
  | ImageDiagnosisValues
  | InputWithSuffixValue
  | RadioWithOtherValue
  | DiagnosisValue;
