import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

// This state is for handling the state of the camera

export type CameraState = {
  cameraPreviewEnabled: boolean;

  // setCameraPreviewEnabled is a function that can be called to enable the camera preview mode.  This sets the styles
  // such that the camera preview "takes over" the screen.
  setCameraPreviewEnabled: (cameraPreviewEnabled: boolean) => void;
};

const useLayoutStore = create(
  devtools<CameraState>(
    (set) => ({
      cameraPreviewEnabled: false,
      setCameraPreviewEnabled: (cameraPreviewEnabled = true) => set({ cameraPreviewEnabled })
    }),
    {
      name: 'checkup-camera'
    }
  )
);

export const useCameraPreviewEnabled = () => useLayoutStore((state) => state.cameraPreviewEnabled);
export const useSetCameraPreviewEnabled = () => useLayoutStore((state) => state.setCameraPreviewEnabled);
