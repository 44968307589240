import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronsRight } from 'lucide-react';
import { ClinicPet } from '@companion-professional/dctypes';
import { Button, ErrorAlert } from '@companion-professional/components';
import { useCreateCheckupForClinicPetId } from '../../hooks/useCreateCheckupForClinicPetId';
import { NoPetsFoundError } from './NoPetsFoundError';
import landerImage from '../../assets/lander-image.jpg';
import { NeedHelpContactSupport } from '../../components/NeedHelpContactSupport';

interface InitialInstructionsProps {
  clinicId: string;
  clinicName: string;
  pets: ClinicPet[];
}

// InitialInstructions is a component that displays a welcome message and a button to create and start the checkup.
// Because a link can have one or multiple pets; this component displays different instructions for each case.
export const InitialInstructions: FunctionComponent<InitialInstructionsProps> = ({ pets, clinicId, clinicName }) => {
  const navigate = useNavigate();
  const { isPending, error, createCheckup } = useCreateCheckupForClinicPetId();

  if (error) {
    return <ErrorAlert title="Error" message={error.message} />;
  }

  if (pets.length === 0) {
    return <NoPetsFoundError />;
  }

  return (
    <>
      <img src={landerImage} alt="Taking video of your dog" />
      <div className="space-y-4 px-4 leading-6 text-primary">
        <p>
          The team at <span className="font-semibold">{clinicName}</span> is requesting an in-home gait assessment of
          your dog.
        </p>
        <p>Click below for instructions and to get started!</p>
      </div>
      <div className="space-y-2 px-4">
        {(pets || []).map((pet) => (
          <Button
            key={pet.clinic_pet_id}
            variant="primary"
            size="large"
            className="w-full text-base sm:text-lg"
            disabled={isPending}
            onClick={async () => {
              const checkupId = await createCheckup({ clinicId, clinicPetId: pet.clinic_pet_id });
              navigate(`/${checkupId}/checkup`);
            }}
          >
            <div className="flex w-full flex-row items-center justify-center">
              <div className="truncate">{pet.pet_name}&nbsp;</div>
              <div>is Ready</div>
              <div>
                <ChevronsRight className="h-6 w-6 sm:h-8 sm:w-8" />
              </div>
            </div>
          </Button>
        ))}
      </div>
      <NeedHelpContactSupport />
    </>
  );
};
