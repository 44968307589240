// resetVideoStreams is a utility function that stops all tracks in a video stream.  This essentially stops the camera
// from capturing video (e.g. releasing the camera).
export const resetVideoStreams = (video: HTMLVideoElement | null) => {
  if (video !== null) {
    const stream = video.srcObject as MediaStream;
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach((track) => {
        track.stop();
      });
    }
  }
};

export type DeviceList = {
  video: MediaDeviceInfo[];
  audio: MediaDeviceInfo[];
};

// getDevices returns the video and audio devices available to the browser
export const getDevices = async (): Promise<DeviceList> => {
  const list: DeviceList = { video: [], audio: [] };
  const devices = await navigator.mediaDevices.enumerateDevices();
  devices.forEach((device) => {
    if (device.kind === 'videoinput') {
      list.video.push(device);
    }
    if (device.kind === 'audioinput') {
      list.audio.push(device);
    }
  });

  return list;
};
