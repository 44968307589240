import React, { FunctionComponent, useState, useEffect, useRef, useCallback } from 'react';
import { UpChunk } from '@mux/upchunk';
import { MuxUploaderDisplay } from './MuxUploaderDisplay';
import { UpChunkAttemptFailure, UpChunkError } from './types';

// DEFAULT_CHUNK_SIZE is the default chunk size that the file will be uploaded in.  Currently set to 10 MB.
// This is used in the Mux's UpChunk options chunkSize value.  From the upchunk docs:
//  > The size in kB of the chunks to split the file into, with the exception of the final chunk which may be smaller.
//  > This parameter must be in multiples of 256.
const DEFAULT_CHUNK_SIZE = 10240;

interface UploaderProps {
  // file is a File object that will be uploaded to Mux
  file: File;

  // onReset is a function that will be called when the user clicks the reset button.
  onReset: () => void;

  // onUploadComplete is a function that will be called when the upload is complete.
  onUploadComplete?: () => void;

  // signedUploadUrl the signed MUX upload URL. This is directly used in the UpChunk.createUpload function (the
  // endpoint parameter).
  signedUploadUrl: string;

  // attempts is the number of times the system should attempt to upload the file.  (defaults to 5)
  attempts?: number;

  // chunkSize is the size (in Kb) of the chunks that the file will be uploaded.  NOTE: This parameter must be in
  // multiples of 256.
  chunkSize?: number;

  // dynamicChunkSize is a flag that determines if the system should dynamically scale the chunkSize up and down to
  // adjust to network conditions. Defaults to false.
  dynamicChunkSize?: boolean;

  // delayBeforeAttempt is the number of seconds to wait before attempting to upload the file again.  (defaults to 1.0)
  delayBeforeAttempt?: number;

  // allowPostUploadReset is a flag that determines if the user should be allowed to reset the upload after it's
  // completed.  (defaults to false)
  allowPostUploadReset?: boolean;

  // onError is a function that will be called when an error occurs during the upload process.  It will be passed a
  // CustomEvent object with the error in the detail field.
  onError?: (error: CustomEvent<UpChunkError>) => void;

  // onAttemptFailure is a function that will be called when an attempt to upload a chunk fails.
  onAttemptFailure?: (error: CustomEvent<UpChunkAttemptFailure>) => void;
}

// MuxUploader is a component that uses Mux's upchunk library to upload a file to Mux.
export const MuxUploader: FunctionComponent<UploaderProps> = ({
  file,
  onReset,
  signedUploadUrl,
  onUploadComplete = () => {},
  onError = () => {},
  onAttemptFailure = () => {},
  attempts = 5,
  dynamicChunkSize = false,
  chunkSize = DEFAULT_CHUNK_SIZE,
  delayBeforeAttempt = 1.0,
  allowPostUploadReset = false
}) => {
  const [progress, setProgress] = useState(0);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [statusMessage, setStatusMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const isUploading = useRef(false);

  // handleUpload creates an upload object and subscribes to events.  This is where the actual upload happens.
  const handleUpload = useCallback(async () => {
    const upload = UpChunk.createUpload({
      endpoint: signedUploadUrl,
      file,
      attempts,
      chunkSize,
      dynamicChunkSize,
      delayBeforeAttempt
    });

    upload.on('error', (error: CustomEvent<UpChunkError>) => {
      setStatusMessage('Upload Error');
      onError(error);
    });

    upload.on('progress', (progress: CustomEvent<number>) => {
      setProgress(progress.detail);
    });

    upload.on('success', () => {
      onUploadComplete();
      setFileUploaded(true);
      setStatusMessage('Upload Complete');
    });

    upload.on('attemptFailure', (failure: CustomEvent<UpChunkAttemptFailure>) => {
      onAttemptFailure(failure);
    });
  }, [signedUploadUrl, file, chunkSize, onUploadComplete]);

  useEffect(() => {
    if (!isUploading.current) {
      isUploading.current = true;
      handleUpload().catch((err) => {
        console.log(err);
        setErrorMessage('Error uploading file');
      });
    }
  }, []);

  return (
    <MuxUploaderDisplay
      progress={progress}
      onReset={onReset}
      statusMessage={statusMessage}
      errorMessage={errorMessage}
      complete={fileUploaded}
      allowPostUploadReset={allowPostUploadReset}
    />
  );
};
