import { painLocationOptions } from './painLocationOptions';
import { DiagnosisOption } from '../imageDiagnosisTypes';

// Adding additional option sets to this object will make them available to the ImageDiagnosis DynamicForm component.
// The keys of this object will be the names of the option sets that can be used in the DynamicForm schema.
const diagnosisOptions = {
  painLocationOptions
} as const;

export type DiagnosisOptionSetName = keyof typeof diagnosisOptions;
export const DiagnosisOptionSets: Record<DiagnosisOptionSetName, DiagnosisOption[]> = diagnosisOptions;
