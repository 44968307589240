import React, { useEffect, useState, useRef, useImperativeHandle, Ref, forwardRef } from 'react';

export interface CountdownTimerHandles {
  start: () => void;
  reset: () => void;
}

interface CountdownTimerProps {
  // seconds is the number of seconds to countdown from (default is 3)
  seconds?: number;

  // onComplete is a callback that is called when the countdown timer is finished
  onComplete?: () => void;
}

// CountdownTimer a component that displays a countdown (in seconds) in the center of the screen.  Once the countdown
// is finished, the onComplete callback is called.
const CDT = ({ seconds = 3, onComplete }: CountdownTimerProps, ref: Ref<CountdownTimerHandles>) => {
  const [isCountdownActive, setIsCountdownActive] = useState<boolean>(false);
  const [recCountdown, setRecCountdown] = useState<number>(seconds);

  const recCountdownRef = useRef<number>(recCountdown);
  const countdownIntervalRef = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    return () => {
      if (countdownIntervalRef.current) {
        clearInterval(countdownIntervalRef.current);
      }
      setIsCountdownActive(false);
    };
  }, []);

  useImperativeHandle(ref, () => ({
    start: () => {
      resetTimeout();
      setIsCountdownActive(true);
      startTimeout();
    },
    reset: () => {
      resetTimeout();
    }
  }));

  const tick = () => {
    if (recCountdownRef.current <= 1) {
      resetTimeout();
      onComplete && onComplete();
      return;
    }

    setRecCountdown((recCountdown) => recCountdown - 1);
    recCountdownRef.current = recCountdownRef.current - 1;
    startTimeout();
  };

  const startTimeout = function () {
    countdownIntervalRef.current = setTimeout(tick, 1000);
  };

  const resetTimeout = function () {
    if (countdownIntervalRef.current) {
      clearTimeout(countdownIntervalRef.current);
    }

    setIsCountdownActive(false);
    recCountdownRef.current = seconds;
    setRecCountdown(seconds);
  };

  if (!isCountdownActive) return null;

  return (
    <>
      <div
        className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center"
        style={{ backgroundColor: 'rgba(0, 0, 0, .45)' }}
      >
        <span
          className={`text-3xl font-bold`}
          style={{ WebkitTextStroke: '1px white', WebkitTextFillColor: 'transparent' }}
        >
          {recCountdown}
        </span>
      </div>
    </>
  );
};

export const CountdownTimer = forwardRef<CountdownTimerHandles, CountdownTimerProps>(CDT);
