import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';

// TODO: Don't close the menu when selecting a radio or checkbox item (maybe a prop to control this?)

// This directory contains the styled components for the dropdown menu.  This will initially be used for the user menu
// that'll contain the logout button, but will eventually it could be used anywhere a popup menu is needed/wanted.
// To see this component in action, checkout the DropdownMenu story, the typescript/stories directory.

// Under the hood, this is using the radix-ui dropdown menu components:
// https://www.radix-ui.com/primitives/docs/components/dropdown-menu

// DropdownMenu is the root component
export const DropdownMenu = DropdownMenuPrimitive.Root;

// DropdownMenuTrigger is the component that triggers the menu to open/close.  The menu can also be opened/closed by
// using the `opened` prop on the DropdownMenu component.
export const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger;

// DropMenuGroup is a container for a group of menu items.
export const DropdownMenuGroup = DropdownMenuPrimitive.Group;

// DropdownMenuPortal, when used, portals the content part that it wraps into the HTML body.
export const DropdownMenuPortal = DropdownMenuPrimitive.Portal;

// DropdownMenuSub is used to create a sub menu that "pops out" in a separate menu box.
export const DropdownMenuSub = DropdownMenuPrimitive.Sub;

// DropdownMenuRadioGroup is used to group radio items together.  This ensures that only one radio item in the group can
// be selected at a time.  This component should contain one or more DropdownMenuRadioItem components.
// Key Parameters:
//   value: string - the value of the selected radio item
//   onValueChange: (value: string) => void - the function that is called when the selected radio item changes
export const DropdownMenuRadioGroup = DropdownMenuPrimitive.RadioGroup;

// Styled dropdown menu components
export { DropdownMenuContent } from './DropdownMenuContent';
export { DropdownMenuSubTrigger } from './DropdownMenuSubTrigger';
export { DropdownMenuSubContent } from './DropdownMenuSubContent';
export { DropdownMenuItem } from './DropdownMenuItem';
export { DropdownMenuSeparator } from './DropdownMenuSeparator';
export { DropdownMenuShortcut } from './DropdownMenuShortcut';
export { DropdownMenuLabel } from './DropdownMenuLabel';
export { DropdownMenuRadioItem } from './DropdownMenuRadioItem';
export { DropdownMenuCheckboxItem } from './DropdownMenuCheckboxItem';
