import React, { FunctionComponent } from 'react';
import { cn } from '@companion-professional/webutils';
import { CONTACT_EMAIL, CONTACT_PHONE } from '../../lib/config';

interface NeedHelpContactSupport {
  className?: string;
}

// NeedHelpContactSupport is a component that displays a message with links to contact (text or email) support for help.
export const NeedHelpContactSupport: FunctionComponent<NeedHelpContactSupport> = ({ className }) => {
  return (
    <div className={cn('text-center text-sm text-gray-600', className)}>
      <p>
        Need help?&nbsp;Please&nbsp;
        <a href={`mailto:${CONTACT_EMAIL}`} className="text-blue-700 underline">
          email
        </a>
        &nbsp;or&nbsp;
        <a href={`sms:${CONTACT_PHONE}`} className="text-blue-700 underline">
          text
        </a>
        &nbsp;support.
      </p>
    </div>
  );
};
