import * as React from 'react';
import { cn } from '@companion-professional/webutils';

interface TableFooterProps extends React.TdHTMLAttributes<HTMLTableSectionElement> {
  // hideWhenMd is used to hide the column when the screen size is medium or smaller.
  hideWhenMd?: boolean;
}

export const TableFooter = React.forwardRef<HTMLTableSectionElement, TableFooterProps>(
  ({ hideWhenMd = false, className, ...props }, ref) => (
    <tfoot
      ref={ref}
      className={cn(
        'bg-muted/50 border-t font-medium [&>tr]:last:border-b-0',
        {
          'hidden md:table-cell': hideWhenMd
        },
        className
      )}
      {...props}
    />
  )
);
TableFooter.displayName = 'TableFooter';
