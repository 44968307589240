import React, { FunctionComponent, useRef } from 'react';
import { cn } from '@companion-professional/webutils';

export interface NativeCaptureProps {
  // captureType can be either image (for taking a photo) or video (for capturing a video).  Defaults to "video".
  captureType?: 'image' | 'video';

  // onFileSelected is the callback for when a file is selected (note this only returns the first file selected if
  // multiple are somehow selected).
  onFileSelected?: (file: File) => void;

  // camera indicates which camera to use. "environment" is typically the back camera and "user" is the front facing
  // camera. Defaults to "environment".
  camera?: 'user' | 'environment';

  // children should contain the icon for the button.  This isn't particularly robust as you could pass anything in
  // here, and it might look terrible.  Opportunity for improvement.
  children?: React.ReactNode;

  className?: string;

  // nativeFilePicker is a flag that determines if the native file picker should be used instead of the camera.  This
  // can be used when we want to allow uploading an existing image or video file and not try to open the camera.
  nativeFilePicker?: boolean;
}

// NativeCapture is a component that allows you to use the native camera (or file picker if nativeFilePicker is set to
// true) on a device to capture/upload an image or video.  This is just a thin wrapper around the <input type="file" />
// component.  Note that this will "pop" the native file selector if a native camera is not available.  We will most
// likely want to have some kind of fallback to the <VideoCapture /> component in the future.
export const NativeCapture: FunctionComponent<NativeCaptureProps> = ({
  captureType = 'video',
  camera = 'environment',
  onFileSelected = () => {},
  className = '',
  children,
  nativeFilePicker = false
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <div
        className={cn('flex w-full cursor-pointer justify-center', className)}
        onClick={() => {
          inputRef.current?.click();
        }}
      >
        {children}
      </div>
      {captureType === 'video' ? (
        // Input for capturing a video
        <input
          id="capture-video"
          type="file"
          accept="video/*"
          capture={nativeFilePicker ? false : camera}
          className="hidden"
          ref={inputRef}
          onChange={() => {
            if (inputRef.current?.files?.[0]) {
              onFileSelected(inputRef.current?.files?.[0]);
            }
          }}
        />
      ) : (
        // Input for capturing an image
        <input
          id="capture-image"
          type="file"
          accept="image/*"
          capture={nativeFilePicker ? false : camera}
          className="hidden"
          ref={inputRef}
          onChange={() => {
            if (inputRef.current?.files?.[0]) {
              onFileSelected(inputRef.current?.files?.[0]);
            }
          }}
        />
      )}
    </>
  );
};
