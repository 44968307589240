import React, { FunctionComponent } from 'react';

interface VideoCaptureWrapperProps {
  children?: React.ReactNode;
}

// VideoCaptureWrapper is the component that wraps VideoCapture cards (applying proper padding, etc.)
export const VideoCaptureWrapper: FunctionComponent<VideoCaptureWrapperProps> = ({ children }) => {
  return <div className="flex h-[330px] w-full flex-col items-center justify-between md:h-[400px]">{children}</div>;
};
