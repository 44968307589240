import * as React from 'react';
import { cn } from '@companion-professional/webutils';

interface TableHeadProps extends React.ThHTMLAttributes<HTMLTableCellElement> {
  // hideWhenMd is used to hide the column when the screen size is medium or smaller.
  hideWhenMd?: boolean;
}

export const TableHead = React.forwardRef<HTMLTableCellElement, TableHeadProps>(
  ({ hideWhenMd = false, className, ...props }, ref) => (
    <th
      ref={ref}
      className={cn(
        'text-muted-foreground p-2 text-left align-middle font-bold [&:has([role=checkbox])]:pr-0',
        {
          'hidden md:table-cell': hideWhenMd
        },
        className
      )}
      {...props}
    />
  )
);
TableHead.displayName = 'TableHead';
