import { DiagnosisImage } from '../imageDiagnosisTypes';

import dogLeftSideImage from './assets/dog-leftside.png';
import dogRightSideImage from './assets/dog-rightside.png';

// TODO: This is a first pass at naming and positioning the pain points on the dog images.  This will most likely need
//  to be reviewed by someone with more expertise in dog anatomy and then updated.
export const dogPainLocations: DiagnosisImage[] = [
  {
    name: 'Left Side',
    imageUrl: dogLeftSideImage,
    points: [
      {
        label: 'Neck',
        name: 'neck',
        x: 250,
        y: 94
      },
      {
        label: 'Abdominal',
        name: 'abdominal',
        x: 357,
        y: 324
      },
      {
        label: 'Back',
        name: 'back',
        x: 387,
        y: 160
      },
      {
        label: 'Left Shoulder Joint',
        name: 'left_shoulder_joint',
        x: 221,
        y: 270
      },
      {
        label: 'Left Elbow',
        name: 'left_elbow',
        x: 243,
        y: 379
      },
      {
        label: 'Left Carpus',
        name: 'left_carpus',
        x: 237,
        y: 444
      },
      {
        label: 'Left Hip Joint',
        name: 'left_hip_joint',
        x: 517,
        y: 249
      },
      {
        label: 'Left Knee',
        name: 'left_knee',
        x: 550,
        y: 341
      },
      {
        label: 'Left Tarsus',
        name: 'left_tarsus',
        x: 607,
        y: 433
      },
      {
        label: 'Left Front Toes',
        name: 'left_front_toes',
        x: 200,
        y: 479
      },
      {
        label: 'Left Back Toes',
        name: 'left_back_toes',
        x: 571,
        y: 480
      }
    ]
  },
  {
    name: 'Right Side',
    imageUrl: dogRightSideImage,
    points: [
      {
        label: 'Neck',
        name: 'neck',
        x: 422,
        y: 92
      },
      {
        label: 'Back',
        name: 'back',
        x: 276,
        y: 162
      },
      {
        label: 'Abdominal',
        name: 'abdominal',
        x: 301,
        y: 334
      },

      {
        label: 'Right Shoulder Joint',
        name: 'right_shoulder_joint',
        x: 429,
        y: 246
      },
      {
        label: 'Right Elbow',
        name: 'right_elbow',
        x: 423,
        y: 391
      },
      {
        label: 'Right Carpus',
        name: 'right_carpus',
        x: 433,
        y: 454
      },
      {
        label: 'Right Hip Joint',
        name: 'right_hip_joint',
        x: 144,
        y: 252
      },
      {
        label: 'Right Knee',
        name: 'right_knee',
        x: 104,
        y: 360
      },
      {
        label: 'Right Tarsus',
        name: 'right_tarsus',
        x: 63,
        y: 452
      },
      {
        label: 'Right Back Toes',
        name: 'right_back_toes',
        x: 93,
        y: 480
      },
      {
        label: 'Right Front Toes',
        name: 'right_front_toes',
        x: 463,
        y: 478
      }
    ]
  }
];
