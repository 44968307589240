import { ButtonProps } from '../Button';
import React, { FunctionComponent } from 'react';

type Size = ButtonProps['size'];
interface IconSizerProps {
  Icon: React.ElementType;
  size: Size;
}
export const IconSizer: FunctionComponent<IconSizerProps> = ({ Icon, size }) => {
  switch (size) {
    case 'small':
      return <Icon className="h-4 w-4" />;
    case 'medium':
      return <Icon className="h-5 w-5" />;
    case 'large':
      return <Icon className="h-6 w-6" />;
    default:
      return <Icon className="h-6 w-6" />;
  }
};
