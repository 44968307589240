import * as React from 'react';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import { RadioGroup } from './RadioGroup';
import { Label } from '../Label';
import { cn } from '@companion-professional/webutils';

export interface RadioGroupWithLabelProps extends React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root> {
  // label is the text that will be displayed above the radio group.  If both label and description are not provided,
  // the label and description section will not be rendered.
  label?: string;

  // description is the text that will be displayed below the label. If both label and description are not provided,
  // the label and description section will not be rendered.
  description?: string;
}

export const RadioGroupWithLabel = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  RadioGroupWithLabelProps
>(({ className, label, description, ...props }, ref) => {
  return (
    <>
      {description || label ? (
        <div
          className={cn({
            'mb-2': !!description && !!label,
            'mb-4': !!label && !description
          })}
        >
          <Label htmlFor={props.id} description={description}>
            {label}
          </Label>
        </div>
      ) : null}
      <RadioGroup className={className} {...props} ref={ref} />
    </>
  );
});
RadioGroupWithLabel.displayName = 'RadioGroupWithLabel';
