import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

// cn is a utility function that combines clsx and tailwind-merge/twMerge.  This is helpful for outputting consistently
// ordered class names.
//   * tailwind-merge/twMerge makes sure that the classes that come at the end of the list override the ones that come
//     before.
//   * clsx is a utility that allows for conditional class names using an object.
//     For example: clsx('foo', { bar: true, baz: false }) returns 'foo bar'
//
// This is a common pattern used in libraries like shadcn/ui.
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
