import React, { FunctionComponent } from 'react';
import { AlertTriangle } from 'lucide-react';
import { Alert, AlertDescription } from '@companion-professional/components';
import { AppHead } from '../../components/AppHead';

export const Home: FunctionComponent = () => {
  return (
    <div className="flex flex-col">
      <AppHead />
      <div className="flex h-80 items-center justify-center">
        {/* I think we might want a page where the user can copy and paste a checkup ID to go to it. So there might
            be an input element here? */}
        <Alert variant="destructive" className="max-w-lg bg-destructive/20">
          <AlertTriangle className="h-4 w-4" />
          <AlertDescription>This page must be accessed via a checkup request.</AlertDescription>
        </Alert>
      </div>
    </div>
  );
};
