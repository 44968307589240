import axios from 'axios';
import {
  CheckupRequestInfo,
  ClinicWithPetsResponse,
  CreateCheckupRequestForPetResult,
  ItemUploadSignedUrl
} from '@companion-professional/dctypes';
import {
  CHECKUP_REQUEST_URL,
  GENERATE_UPLOAD_URL,
  CREATE_CHECKUP_FOR_CLINIC_PET_URL,
  GET_CLINIC_BY_SLUG_URL
} from './config';

// This file contains all the API HTTP requests for the application.

// getCheckupRequest is a function that fetches a single checkup request.
export const getCheckupRequest = async (checkupRequestId: string): Promise<CheckupRequestInfo> => {
  const response = await axios.get<CheckupRequestInfo>(`${CHECKUP_REQUEST_URL}/${checkupRequestId}`);
  return response.data;
};

// getItemUploadUrl is a function that fetches a signed URL for uploading a video to MUX
export const getItemUploadUrl = async (itemId: string): Promise<ItemUploadSignedUrl> => {
  const response = await axios.post<ItemUploadSignedUrl>(`${GENERATE_UPLOAD_URL}/${itemId}`);
  return response.data;
};

// getClinicBySlug is a function that fetches a clinic by its slug.  It can also take an optional petIds parameter that
// if provided, returns pet information (e.g. names).
export const getClinicBySlug = async (
  clinicSlug: string,
  clinicPetIds: string | null
): Promise<ClinicWithPetsResponse> => {
  const queryParams: Record<string, unknown> = {};
  if (clinicPetIds) {
    queryParams['pet_ids'] = clinicPetIds;
  }
  const response = await axios.get<ClinicWithPetsResponse>(`${GET_CLINIC_BY_SLUG_URL}/${clinicSlug}`, {
    params: queryParams
  });
  return response.data;
};

// createCheckupForClinicPetId is a function that creates a checkup for a clinic (clinicId) and that clinic's pet ID
// (clinicPetId).  Returns the checkup ID.
export const createCheckupForClinicPetId = async (clinicId: string, clinicPetId: string): Promise<string> => {
  const response = await axios.post<CreateCheckupRequestForPetResult>(CREATE_CHECKUP_FOR_CLINIC_PET_URL, {
    clinic_id: clinicId,
    clinic_pet_id: clinicPetId
  });
  return response.data.checkup_id;
};
