import * as React from 'react';
import * as SelectPrimitive from '@radix-ui/react-select';
import { cn } from '@companion-professional/webutils';
import { ChevronDown } from 'lucide-react';

// SelectTrigger is the button that toggles the content of the Select.
export const SelectTrigger = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Trigger
    ref={ref}
    className={cn(
      [
        'border-input',
        'bg-widget',
        'ring-offset-background',
        'placeholder:text-muted-foreground',
        'focus:ring-ring',
        'flex',
        'h-10',
        'w-full',
        'items-center',
        'justify-between',
        'rounded-md',
        'border',
        'px-3',
        'py-2',
        'text-sm',
        'focus:outline-none',
        'focus:ring-2',
        'focus:ring-offset-2',
        'disabled:cursor-not-allowed',
        'disabled:opacity-50',
        '[&>span]:line-clamp-1'
      ],
      className
    )}
    {...props}
  >
    {children}
    <SelectPrimitive.Icon asChild>
      <ChevronDown className="h-4 w-4 opacity-50" />
    </SelectPrimitive.Icon>
  </SelectPrimitive.Trigger>
));
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;
