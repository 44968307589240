import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { getOSName, isMobile } from '../../lib/ua';

// MobileCameraHelpLink is a component that provides a link to the help page for mobile camera issues.  The link is
// generated based on the user's OS (currently only iOS and Android are supported).  Returns null if the user is not
// on a mobile device.
export const MobileCameraHelpLink: FunctionComponent = () => {
  if (!isMobile()) {
    return null;
  }

  let linkUrl = '';
  switch (getOSName()) {
    case 'iOS':
      linkUrl = `../ios-help`;
      break;
    case 'Android':
      linkUrl = `../android-help`;
      break;
    default:
      // This is an OS that we don't have specific help for.
      return null;
  }

  return (
    <div className="mt-4">
      <Link to={linkUrl} className="text-sm text-blue-600 underline sm:text-md">
        Need help with your mobile camera?
      </Link>
    </div>
  );
};
