import React, { FunctionComponent } from 'react';
import { ClinicWithPetsResponse } from '@companion-professional/dctypes';
import { InitialInstructions } from './InitialInstructions';

interface ClinicLanderPetsFoundProps {
  clinic: ClinicWithPetsResponse;
}

// ClinicLanderPetsFound is the component that is displayed when pets are found for the clinic.
export const ClinicLanderPetsFound: FunctionComponent<ClinicLanderPetsFoundProps> = ({ clinic }) => {
  return (
    <div className="flex h-full w-full max-w-lg flex-1 flex-col justify-between gap-4 pb-4">
      <InitialInstructions clinicId={clinic.clinic_id} pets={clinic.pets || []} clinicName={clinic.clinic_name} />
    </div>
  );
};
