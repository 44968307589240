import { useQuery } from '@tanstack/react-query';
import { ClinicWithPetsResponse } from '@companion-professional/dctypes';
import { getClinicBySlug } from '../lib/api';

// useClinic is a hook that fetches a clinic by its slug.  It can also take an optional petIds parameter that if
// provided, returns pet information (e.g. names) retrieved from the clinic's PIMS.
export const useClinic = (clinicSlug: string, clinicPetIds: string | null) => {
  const {
    isPending,
    error,
    data: clinic
  } = useQuery<ClinicWithPetsResponse>({
    queryKey: ['clinic-slug', clinicSlug, clinicPetIds],
    queryFn: () => getClinicBySlug(clinicSlug, clinicPetIds),
    staleTime: 1000 * 60 // 1 minute
  });

  return {
    isPending,
    error,
    clinic
  };
};
