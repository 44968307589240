import React, { FunctionComponent } from 'react';
import { companionProBranding } from '@companion-professional/branding';
import { CompanionProLogo } from '@companion-professional/components';

// AppHead is the top of the page that contains the logo and title.
export const AppHead: FunctionComponent = () => {
  return (
    <div className="flex w-full flex-1 items-center justify-center">
      <div className="absolute flex flex-col items-center justify-center">
        <CompanionProLogo
          lineColor={companionProBranding.logoColor}
          backgroundColor={companionProBranding.logoBackgroundColor}
          className="h-32 w-32 md:h-48 md:w-48 portrait:h-32 portrait:w-32 landscape:h-32 landscape:w-32"
        />
        <div className="text-xl font-bold text-primary md:text-3xl landscape:text-xl">Companion Vision</div>
      </div>
    </div>
  );
};
