import { Branding, CustomTailwindTheme } from './branding-types';

// companionProColors are the named colors used in the Companion branding.
const companionProColors = {
  leafyGreen: '#26575C',
  leafyLightGreen: '#488E94',
  beagleBeige: '#F2E9D8',
  basset: '#E6D5B7',
  bone: '#F8F3EB',
  collieCharcoal: '#3E3E3E',
  rhodesianRed: '#D28060',
  biscuit: '#B15F3F',
  setterSunshine: '#E6B455',
  pureWhite: '#FFFFFF'
};

// themeCustomizations are the Tailwindcss customizations that can be used in the `./tailwind.config.ts` configuration
// file.  The values for these settings are a union of the default Tailwindcss theme settings
// (https://tailwindcss.com/docs/theme) and those defined in the CustomTailwindTheme type.
const themeCustomizations: CustomTailwindTheme = {
  extend: {
    colors: {
      primary: companionProColors.leafyGreen,
      'primary-foreground': companionProColors.pureWhite,

      secondary: companionProColors.rhodesianRed,
      'secondary-foreground': companionProColors.pureWhite,

      destructive: companionProColors.biscuit,
      'destructive-foreground': companionProColors.pureWhite,

      'accent-foreground': companionProColors.collieCharcoal,

      // brand-background is the background color for the entire page.
      'brand-background': companionProColors.bone,

      // widget is the background color for the widgets on the page (widget-foreground is the color of the text and
      // icons).  This is used for the background of the of items like inputs, menu dropdowns, etc.  I'm not 100%
      // happen with the name, so this will most likely change in the very near future.
      widget: companionProColors.pureWhite,
      'widget-foreground': companionProColors.collieCharcoal
    },
    fontFamily: {
      // NOTE: that the Poppins and Roboto fonts will need to be loaded on the webpages that use this branding. This
      // can be done by adding a link to the font in the <head> of the page.
      // For example:
      //    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&family=Roboto:wght@400;700&display=swap"
      //          rel="stylesheet" />
      //
      // TODO: Have the fonts assets be part of the branding package.
      sans: ['Poppins', 'ui-sans-serif', 'system-ui'],
      alt: ['Roboto', 'ui-sans-serif', 'system-ui']
    },
    fontSize: {
      '2xs': '.625rem', // 10px
      xs: '.6875rem', // 11px
      sm: '.75rem', // 12px
      md: '.875rem', // 14px,
      base: '1rem', // 16px,
      lg: '1.125rem', // 18px,
      xl: '1.25rem', //  20px
      '2xl': '1.5rem', // 24px
      '3xl': '2rem', // 32px
      '4xl': '2.25rem', // 36px
      '5xl': '3rem', // 48px
      '6xl': '3.75rem', // 60px
      '7xl': '4.5rem' // 72px
    },
    screens: {
      sm: '640px',
      md: '800px',
      lg: '800px',
      xl: '1170px',
      max: '1230px'
    }
  }
};

export const companionProBranding: Branding = {
  tailwindCustomTheme: themeCustomizations,
  logoColor: companionProColors.leafyGreen,
  logoBackgroundColor: companionProColors.bone
};
