import React, { FunctionComponent } from 'react';
import { AppHead } from '../AppHead';

interface SplitLayoutProps {
  children: React.ReactNode;
}

// SplitLayout is a layout that put the AppHead (logo) in the top half of the page and the content at the lower half.
export const SplitLayout: FunctionComponent<SplitLayoutProps> = ({ children }) => {
  return (
    <div className="flex h-full w-full flex-col items-center justify-between">
      <AppHead />
      <div className="flex w-full flex-1 items-center justify-center">{children}</div>
    </div>
  );
};
