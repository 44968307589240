import { useMutation } from '@tanstack/react-query';
import { createCheckupForClinicPetId } from '../lib/api';

type CreateCheckupValues = {
  clinicId: string;
  clinicPetId: string;
};

// useCreateCheckup is a hook that creates a new checkup for a pet using the clinic pet id (the id used by the clinic
// PIMS) and the clinicId.
export const useCreateCheckupForClinicPetId = () => {
  const {
    mutateAsync: createCheckup,
    isPending,
    error
  } = useMutation({
    mutationFn: async ({ clinicId, clinicPetId }: CreateCheckupValues) =>
      createCheckupForClinicPetId(clinicId, clinicPetId)
  });

  return {
    createCheckup,
    isPending,
    error
  };
};
