import React, { FunctionComponent } from 'react';
import { RecordButton } from './RecordButton';
import { CameraCaptureState, CameraCaptureStates } from './inBrowserCameraTypes';

interface RecordingControlsProps {
  cameraCaptureState: CameraCaptureState;
  startRecording: () => void;
  stopRecording: () => void;
  className?: string;

  // children (if provided) is the content that is displayed to the right of the record button.
  children?: React.ReactNode;
}

// RecordingControls is a component that displays the record button and any additional content (to the right of the
// record button) all inside a semi-transparent pill box.  The record button changes based on the cameraCaptureState.
export const RecordingControls: FunctionComponent<RecordingControlsProps> = ({
  cameraCaptureState,
  stopRecording,
  startRecording,
  children,
  className
}) => {
  if (cameraCaptureState === CameraCaptureStates.REVIEWING) {
    return null;
  }

  return (
    <div className={className}>
      <div className="m-3 flex items-center justify-center gap-3 rounded-full bg-black px-6 py-3 opacity-70">
        {cameraCaptureState === 'RECORDING' ? (
          <RecordButton buttonLook="stop" onClick={stopRecording} />
        ) : (
          <RecordButton buttonLook="record" onClick={startRecording} />
        )}
        {children}
      </div>
    </div>
  );
};
