import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type SettingsState = {
  videoDeviceId: string | null;
  audioDeviceId: string | null;
  enableCountdown: boolean;
  setVideoDeviceId: (videoDeviceId: string) => void;
  setAudioDeviceId: (audioDeviceId: string) => void;
  setEnableCountdown: (enableCountdown: boolean) => void;
};

export const useSettingsStore = create(
  persist<SettingsState>(
    (set) => ({
      videoDeviceId: null,
      audioDeviceId: null,
      enableCountdown: true,
      setVideoDeviceId: (videoDeviceId: string) => set({ videoDeviceId }),
      setAudioDeviceId: (audioDeviceId: string) => set({ audioDeviceId }),
      setEnableCountdown: (enableCountdown: boolean) => set({ enableCountdown })
    }),
    {
      // name is the key that the state will be stored under in local storage.
      name: 'video-recorder-settings'
    }
  )
);

// Some facade functions to make it easier to use the Zustand store and to make it easier to migrate to another state
// management library (if needed).
export const useVideoDeviceId = () => useSettingsStore((state) => state.videoDeviceId);
export const useAudioDeviceId = () => useSettingsStore((state) => state.audioDeviceId);
export const useEnableCountdown = () => useSettingsStore((state) => state.enableCountdown);
export const useSetVideoDeviceId = () => useSettingsStore((state) => state.setVideoDeviceId);
export const useSetAudioDeviceId = () => useSettingsStore((state) => state.setAudioDeviceId);
export const useSetEnableCountdown = () => useSettingsStore((state) => state.setEnableCountdown);
