import { DiagnosisImage } from '../imageDiagnosisTypes';
import { dogPainLocations } from './dogPainLocations';

// Adding additional image sets to this object will make them available to the ImageDiagnosis DynamicForm component.
// The keys of this object will be the names of the image sets that can be used in the DynamicForm schema.
const diagnosisImages = {
  dogPainLocations
} as const;

export type DiagnosisImageSetName = keyof typeof diagnosisImages;
export const DiagnosisImageSets: Record<DiagnosisImageSetName, DiagnosisImage[]> = diagnosisImages;
