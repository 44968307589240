import * as React from 'react';
import * as LabelPrimitive from '@radix-ui/react-label';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '@companion-professional/webutils';

const labelVariants = cva([
  'text-base',
  'font-medium',
  'leading-none',
  'peer-disabled:cursor-not-allowed',
  'peer-disabled:opacity-70'
]);

export interface LabelProps
  extends React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root>,
    VariantProps<typeof labelVariants> {
  // description, if provided, will be rendered below the label.
  description?: string;

  // wrapperClassName is a class name that will be applied to the wrapper div that contains the label and description.
  wrapperClassName?: string;
}

// Label is like the html <label/> element, but also has a description prop.  The forwarded ref is associated with the
// label element, so if you pass in a prop like htmlFor, it will be applied to the label element.
export const Label = React.forwardRef<React.ElementRef<typeof LabelPrimitive.Root>, LabelProps>(
  ({ className, description, wrapperClassName, ...props }, ref) => (
    <div className={cn('flex flex-col', wrapperClassName)}>
      <LabelPrimitive.Root ref={ref} className={cn(labelVariants(), className)} {...props} />
      {description ? <p className="text-muted-foreground pt-1 text-[0.8rem]">{description}</p> : null}
    </div>
  )
);
Label.displayName = LabelPrimitive.Root.displayName;
