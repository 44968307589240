import { z } from 'zod';

export type DiagnosisImagePoint = {
  name: string;
  label: string;
  x: number;
  y: number;
};

export type DiagnosisImage = {
  name: string;
  imageUrl: string;
  points?: DiagnosisImagePoint[];
};

export type DiagnosisOption = {
  label: string;
  value: string | number;
  color?: string;
};

const DiagnosisValueSchema = z.union([z.string(), z.number(), z.boolean(), z.undefined(), z.null()]);
export type DiagnosisValue = z.infer<typeof DiagnosisValueSchema>;

// isDiagnosisValue checks if a value is a DiagnosisValue.  This also provides a type guard for TypeScript.
export const isDiagnosisValue = (value: unknown): value is DiagnosisValue => {
  return DiagnosisValueSchema.safeParse(value).success;
};

export const ImageDiagnosisValuesSchema = z.record(z.string(), DiagnosisValueSchema);
export type ImageDiagnosisValues = z.infer<typeof ImageDiagnosisValuesSchema>;
