import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '@companion-professional/webutils';

export const buttonVariants = cva(
  [
    'text-lg',
    'inline-flex',
    'items-center',
    'justify-center',
    'whitespace-nowrap',
    'rounded-full',
    'text-sm',
    'font-extrabold',
    'ring-offset-background',
    'transition-colors',
    'focus-visible:outline-none',
    'focus-visible:ring-2',
    'focus-visible:ring-ring',
    'focus-visible:ring-offset-2',
    'disabled:pointer-events-none',
    'disabled:opacity-50',
    'uppercase',
    'leading-none',
    'tracking-[.08em]'
  ],
  {
    /** Variants are different types of buttons that come fully styled */
    variants: {
      variant: {
        primary: ['bg-primary', 'text-primary-foreground', 'hover:bg-primary/70'],
        destructive: ['bg-destructive', 'text-destructive-foreground', 'hover:bg-destructive/80'],
        outline: [
          'border border-input',
          'bg-background',
          'hover:bg-accent',
          'hover:bg-secondary',
          'hover:text-secondary-foreground'
        ],
        secondary: ['bg-secondary', 'text-secondary-foreground', 'hover:bg-secondary/80'],
        ghost: [
          'hover:bg-accent',
          'hover:text-accent-foreground',
          'focus:outline-none',
          'focus:ring-0',
          'focus:ring-transparent',
          'focus:no-underline',
          'focus:shadow-none',
          'border-none'
        ],
        link: ['text-primary', 'underline-offset-4', 'hover:underline']
      },
      size: {
        thin: 'h-7 px-3 py-1 text-sm font-semibold',
        small: 'h-9 px-4 py-1 text-sm',
        medium: 'h-10 px-6 py-2 text-lg',
        large: 'h-11 px-8 py-4 text-xl',
        icon: 'h-10 w-10'
      }
    },
    defaultVariants: {
      variant: 'primary',
      size: 'medium'
    }
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

// Button is a styled button component that can be used to create a variety of button styles.  It takes all the props
// that a normal <button /> would take, but the type is defaulted to 'button'.  The additional props are:
// * variant: The variant of the button (primary, destructive, outline, secondary, ghost, link).
// * size: The size of the button (thin, small, medium, large, icon).
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, type = 'button', ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} type={type} {...props} />;
  }
);
Button.displayName = 'Button';

export { Button };
