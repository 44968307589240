import * as React from 'react';
import { cn } from '@companion-professional/webutils';

export const Table = React.forwardRef<HTMLTableElement, React.HTMLAttributes<HTMLTableElement>>(
  ({ className, ...props }, ref) => (
    <div className="relative w-full overflow-auto">
      <table
        ref={ref}
        className={cn('w-full caption-bottom bg-widget text-md text-widget-foreground', className)}
        {...props}
      />
    </div>
  )
);
Table.displayName = 'Table';
