import React, { FunctionComponent } from 'react';

interface ProgressCircleProps {
  // value is a number between 0 and 100 that represents the percentage of the progress bar that should be filled.
  value?: number | null;
}

// ProgressCircle is a component that displays a circular progress bar with a percentage value in the center.
export const ProgressCircle: FunctionComponent<ProgressCircleProps> = ({ value }) => {
  const size = 50;
  const circumference = size * 2 * Math.PI;
  if (typeof value !== 'number') {
    return null;
  }

  return (
    <div className="inline-flex items-center justify-center overflow-hidden rounded-full">
      <svg className="h-32 w-32">
        <circle
          className="text-gray-300"
          strokeWidth="5"
          stroke="currentColor"
          fill="transparent"
          r={size}
          cx="64"
          cy="64"
        />
        <circle
          className="text-primary"
          strokeWidth="5"
          strokeDasharray={circumference}
          strokeDashoffset={circumference - (value / 100) * circumference}
          strokeLinecap="round"
          stroke="currentColor"
          fill="transparent"
          r={size}
          cx="64"
          cy="64"
        />
      </svg>
      <span className="absolute text-2xl">{value}%</span>
    </div>
  );
};
