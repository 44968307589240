import * as React from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import { cn } from '@companion-professional/webutils';

export const TooltipProvider = TooltipPrimitive.Provider;
export const Tooltip = TooltipPrimitive.Root;
export const TooltipTrigger = TooltipPrimitive.Trigger;

// Tooltip is a component that displays a text box when hovering over an element.  It extends and styles the
// react-tooltip component set from the radix-ui library.
// Here is a sample usage:
// <TooltipProvider>
//   <Tooltip>
//     <TooltipTrigger>Hover</TooltipTrigger>
//     <TooltipContent>
//       <p>Here is some helpful info</p>
//     </TooltipContent>
//   </Tooltip>
// </TooltipProvider>
export const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={cn(
      'animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out bg-widget text-widget-foreground ' +
        'data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2' +
        'data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2' +
        'data-[side=top]:slide-in-from-bottom-2 z-50 overflow-hidden rounded-md border px-3 py-1.5 text-sm shadow-md',
      className
    )}
    {...props}
  />
));
TooltipContent.displayName = TooltipPrimitive.Content.displayName;
