import React from 'react';
import { Button, ButtonProps } from '../Button';
import { cn } from '@companion-professional/webutils';
import { IconSizer } from './IconSizer';

interface IconTextButtonProps extends ButtonProps {
  Icon: React.ElementType;
}

// IconTextButton is a button that has an icon (to the left) and text (or children) to the right.  This was designed
// to work with lucide icons, but other properly similar icons should work as well.
export const IconTextButton = React.forwardRef<HTMLButtonElement, IconTextButtonProps>(
  ({ Icon, children, className, size, ...props }, ref) => {
    return (
      <Button
        ref={ref}
        {...props}
        size={size}
        className={cn('flex flex-row items-center justify-center gap-2', className)}
      >
        <IconSizer Icon={Icon} size={size} />
        {children}
      </Button>
    );
  }
);
IconTextButton.displayName = 'IconButton';
