import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '@companion-professional/webutils';

export * from './AlertTitle';
export * from './AlertDescription';

const alertVariants = cva(
  [
    'relative',
    'w-full',
    'rounded-lg',
    'border',
    'p-4',
    '[&>svg~*]:pl-7',
    '[&>svg+div]:translate-y-[-3px]',
    '[&>svg]:absolute',
    '[&>svg]:left-4',
    '[&>svg]:top-4',
    '[&>svg]:text-foreground'
  ],
  {
    variants: {
      variant: {
        default: 'bg-background text-foreground',
        destructive: 'border-destructive/50 text-destructive dark:border-destructive [&>svg]:text-destructive'
      }
    },
    defaultVariants: {
      variant: 'default'
    }
  }
);

// Alert is a box (non-popup) for displaying important messages to the user.
export const Alert = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof alertVariants>
>(({ className, variant, ...props }, ref) => (
  <div ref={ref} role="alert" className={cn(alertVariants({ variant }), className)} {...props} />
));
Alert.displayName = 'Alert';
