import { create } from 'zustand';
import { persist } from 'zustand/middleware';

// TableFilters is a map of columnId to an array of selected options (i.e. the selected filter options for a column)
type TableFilters = Record<string, string[]>;

// AllFilters is a map of tableName to TableFilters
type AllFilters = Record<string, TableFilters>;

export type FilterState = {
  selectedFilterOptions: AllFilters;

  // setSelectedOptionsForColumn sets the selected filter options for a column in a table.
  setSelectedOptionsForColumn: (tableName: string, columnId: string, selectedOptions: string[]) => void;

  // clearStoredFilters clears all the stored filters for only tables with the provided tableName.
  clearStoredFilters: (tableName: string) => void;
};

// filterStore is a zustand store that holds the selected filter options for all tables.  Currently, this only applies
// to the FacetedFilter component; the date range and text filters are not stored.
export const filterStore = create(
  persist<FilterState>(
    (set, get) => ({
      selectedFilterOptions: {},
      setSelectedOptionsForColumn: (tableName, columnId, selectedOptions) => {
        const currentSelectedStatuses = get().selectedFilterOptions;
        set({
          selectedFilterOptions: {
            ...currentSelectedStatuses,
            [tableName]: {
              ...currentSelectedStatuses[tableName],
              [columnId]: selectedOptions
            }
          }
        });
      },
      clearStoredFilters: (tableName) => {
        const options = get().selectedFilterOptions;
        if (options[tableName] !== undefined) {
          Object.keys(options[tableName] as TableFilters).forEach((columnId) => {
            (options[tableName] as TableFilters)[columnId] = [];
          });
        }

        set({
          selectedFilterOptions: options
        });
      }
    }),
    {
      name: 'sticky-table-filters'
    }
  )
);

export const useClearStoredFilters = () => filterStore((state) => state.clearStoredFilters);
export const useSetSelectedOptionsForColumn = () => filterStore((state) => state.setSelectedOptionsForColumn);
