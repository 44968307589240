import React, { FunctionComponent } from 'react';
import { Button } from '@companion-professional/components';

// GenerateError is a page that throws an error when the button is clicked.  This is useful for testing error
// boundaries and error reporting tools (like Sentry).
export const GenerateError: FunctionComponent = () => {
  return (
    <div className="flex flex-col justify-center">
      <Button
        onClick={() => {
          throw new Error('This is a test error from checkup.');
        }}
      >
        Generate Error
      </Button>
    </div>
  );
};
