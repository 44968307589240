import UAParser from 'ua-parser-js';

const uaParser = new UAParser();

export const isMobile = (): boolean => uaParser.getDevice().type === 'mobile';

const nativeBrowsers = [
  { os: 'iOS', browser: 'Mobile Safari' },
  { os: 'Android', browser: 'Chrome' }
];

// isNativeMobileBrowser returns true if the user is using a native mobile browser (e.g. Safari on iOS, Chrome on
// Android).
export const isNativeMobileBrowser = () => {
  return nativeBrowsers.some(
    (selector) => selector.os === uaParser.getOS().name && selector.browser === uaParser.getBrowser().name
  );
};

export const getBrowserName = () => uaParser.getBrowser().name;

export const getOSName = () => uaParser.getOS().name;
