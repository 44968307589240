import { DiagnosisOption } from '../imageDiagnosisTypes';

// painLocationOptions is a list of options that can be selected for a pain location.
// TODO: the colors here are hard-coded and should be replaced with a theme color.
export const painLocationOptions: DiagnosisOption[] = [
  {
    label: '-',
    value: 0,
    color: '#6C9E6E'
  },
  {
    label: '+',
    value: 1,
    color: '#E6B455'
  },
  {
    label: '++',
    value: 2,
    color: '#D28060'
  },
  {
    label: '+++',
    value: 3,
    color: '#c85b30'
  }
];
