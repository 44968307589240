import { useQuery } from '@tanstack/react-query';
import { CheckupRequestInfo } from '@companion-professional/dctypes';
import { getCheckupRequest } from '../lib/api';

// useCheckupRequest is a hook that fetches a single checkup request.
//
// It returns:
// - isPending: A boolean that indicates if the request is currently in progress.
// - error: An error object if the request failed.
// - checkupRequest: A CheckupRequestInfo object.
export const useCheckupRequest = (checkupRequestId: string) => {
  const {
    isPending,
    error,
    data: checkupRequest
  } = useQuery<CheckupRequestInfo>({
    queryKey: ['checkupRequest', checkupRequestId],
    queryFn: () => getCheckupRequest(checkupRequestId),

    // Setting the stale time to 30 seconds so that we're not constantly fetching the same data from the server.  We
    // might want to increase this value depending on the user's behavior (e.g. if they hang out on the landing page
    // for over 30 seconds, and we expect that the data will most likely stay the same, we might want to increase the
    // time).
    staleTime: 1000 * 30 // 30 seconds
  });

  return {
    isPending,
    error,
    checkupRequest
  };
};
