import React, { FunctionComponent } from 'react';
import { BottomButton } from '../BottomButton';
import { ArrowBigLeft } from 'lucide-react';
import { NeedHelpContactSupport } from '../NeedHelpContactSupport';
import { useNavigate } from 'react-router-dom';

interface HelpLayoutProps {
  children: React.ReactNode;
}

// HelpLayout is a layout component that is used for help pages (iOSHelp, AndroidHelp, etc.).  The passed children are
// displayed in the main content area of the layout.
export const HelpLayout: FunctionComponent<HelpLayoutProps> = ({ children }) => {
  const navigate = useNavigate();

  return (
    <div className="flex h-full w-full flex-1 flex-col justify-between pb-4">
      <div className="mx-4 flex-1">{children}</div>
      <div className="px-4">
        <BottomButton
          onClick={() => {
            navigate('../checkup');
          }}
        >
          <ArrowBigLeft className="mr-2 w-5" />
          Back to Checkup
        </BottomButton>
      </div>
      <NeedHelpContactSupport />
    </div>
  );
};
