import { isAxiosError, AxiosError } from 'axios';
import { ApiErrorResponse } from '@companion-professional/dctypes';

// getErrorMessageFromAxiosError is a utility that takes an error and returns a string that represents the message
// content of the error message.  If the error is an AxiosError, it will attempt to extract the error message from the
// response (assuming it is of the type ApiErrorResponse).  If the error is a general Error type, it will return the
// message.  If it's neither, it will return the defaultErrorMessage.
export const getErrorMessageFromAxiosError = (
  err: AxiosError<ApiErrorResponse> | unknown,
  defaultErrorMessage = 'Unknown error'
): string => {
  if (isAxiosError<ApiErrorResponse>(err)) {
    return err.response?.data?.error || defaultErrorMessage;
  } else if (err instanceof Error) {
    return err.message || defaultErrorMessage;
  }

  return defaultErrorMessage;
};
