import React, { FunctionComponent } from 'react';

const sizes = {
  small: 'h-5 w-5',
  medium: 'h-12 w-12',
  large: 'h-24 w-24'
};

interface LoaderSpinnerProps {
  // size of the spinner.  Defaults to medium.
  size?: keyof typeof sizes;
}

// LoaderSpinner is a component that renders an animated spinner.  This can be used to indicate that a page is loading.
// The SVG I'm using here is very basic, we can replace it with something better if we want.
// Comes in three sizes: small, medium, and large.  Defaults to medium.
export const LoaderSpinner: FunctionComponent<LoaderSpinnerProps> = ({ size = 'medium' }) => {
  return (
    <div className="flex justify-center text-primary">
      <svg
        className={`-ml-1 mr-3 ${sizes[size]} animate-spin opacity-75`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </div>
  );
};
