import React, { FunctionComponent, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { ErrorAlert, QueryLoaderWrapper } from '@companion-professional/components';
import { ClinicLander } from './pages/ClinicLander';
import { useClinic } from './hooks/useClinic';
import { useSetHeaderBarTitle } from './state/headerBar';

const ClinicLanderSearchParams = {
  clinicPetId: 'p'
};

export type ClinicLanderCheckupUrlParams = {
  clinicSlug: string;
};

// ClinicLanderLoader is a component that fetches a clinic by its slug and displays a welcome message.
export const ClinicLanderLoader: FunctionComponent = () => {
  const { clinicSlug } = useParams<ClinicLanderCheckupUrlParams>();
  const [searchParams] = useSearchParams();
  const clinicPetIds = searchParams.get(ClinicLanderSearchParams.clinicPetId);
  const setHeaderBarTitle = useSetHeaderBarTitle();

  useEffect(() => {
    setHeaderBarTitle(`Checkup`);
  }, []);

  if (!clinicSlug || clinicSlug === '') {
    return <ErrorAlert title="Clinic Not Found" message="We were unable to find your clinic in our system." />;
  }

  const { clinic, error, isPending } = useClinic(clinicSlug, clinicPetIds);

  return (
    <QueryLoaderWrapper isPending={isPending} error={error}>
      {clinic ? <ClinicLander clinic={clinic} /> : null}
    </QueryLoaderWrapper>
  );
};
