// TODO: Unfortunately, the Matomo library does not have TypeScript definitions, the following code is a workaround to
//   allow the Matomo library to be used in TypeScript.  More robust typing should be added in the future.
//   Maybe look at the (currently abandoned) @datapunt/matomo-tracker-react package for inspiration.
declare global {
  interface Window {
    _mtm: any;
    _paq: any[];
  }
}

declare const __MATOMO_URLS__: Record<string, string>;

// getMatomoUrl returns the Matomo URL for the given environment.  These environment URLs are defined in the
// vite.config.ts file.
export const getMatomoUrl = (env: string): string | undefined => {
  return __MATOMO_URLS__?.[env];
};

// trackEvent tracks an event in Matomo.  This can be used for tracking of events that don't have a specific page
// view (like clicking a button that changes the state of the page).
export const trackEvent = (category: string, action: string, name: string, value?: number): void => {
  if (window._paq) {
    if (value === undefined) {
      window._paq.push(['trackEvent', category, action, name]);
      return;
    }
    window._paq.push(['trackEvent', category, action, name, value]);
  }
};
