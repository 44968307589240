import * as React from 'react';
import * as SelectPrimitive from '@radix-ui/react-select';
import { cn } from '@companion-professional/webutils';

// SelectLabel is a non-selectable text element that's used inside the SelectContent component.
export const SelectLabel = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Label ref={ref} className={cn('py-1.5 pl-8 pr-2 text-sm font-semibold', className)} {...props} />
));
SelectLabel.displayName = SelectPrimitive.Label.displayName;
