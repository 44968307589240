import React from 'react';
import CheckboxPrimitive from '@radix-ui/react-checkbox';
import { Checkbox, CheckboxProps } from '../Checkbox';
import { Label } from '../Label';

export interface CheckboxWithLabelProps extends CheckboxProps {
  label: string;
  description?: string;
  children?: React.ReactNode;
}

export const CheckboxWithLabel = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  CheckboxWithLabelProps
>(({ className, id, name, label, description, ...props }, ref) => {
  const checkboxId = id || name || 'checkbox';

  return (
    <div className="flex flex-row items-start space-x-4">
      <Checkbox {...props} ref={ref} id={checkboxId} name={name} />
      <Label htmlFor={checkboxId} description={description}>
        {label}
      </Label>
    </div>
  );
});
