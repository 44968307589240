import React, { FunctionComponent } from 'react';

interface CompanionProLogoProps {
  lineColor?: string;
  backgroundColor?: string;
  className?: string;
}

// This is just a simple SVG logo to fill the void until we get a real one or implement a system for theming (like for
// a white-label version)

export const CompanionProLogo: FunctionComponent<CompanionProLogoProps> = ({
  lineColor = '#000000',
  backgroundColor = '#FFFFFF',
  className = ''
}) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 64 64" className={className}>
    <g>
      <path
        fill={lineColor}
        d="M58,62H6c-2.21,0-4-1.79-4-4V6c0-2.21,1.79-4,4-4h52c2.21,0,4,1.79,4,4v52C62,60.21,60.21,62,58,62z"
      />
      <path
        fill={backgroundColor}
        d="M56,58H8c-1.1,0-2-0.9-2-2V8c0-1.1,0.9-2,2-2h48c1.1,0,2,0.9,2,2v48C58,57.1,57.1,58,56,58z"
      />
      <path
        fill={lineColor}
        d="M38.08,29.14c1.07,0,1.97-0.89,1.97-1.97l0,0c0-1.07-0.89-1.97-1.97-1.97l0,0c-1.07,0-1.97,0.89-1.97,1.97l0,0
		C36.3,28.24,37.01,29.14,38.08,29.14L38.08,29.14z"
      />
      <path
        fill="none"
        d="M40.94,54.86c-0.36-1.97-0.54-3.93-0.54-5.9c0-2.14,0.18-4.47,1.07-6.61c0.72-2.14,2.15-4.29,4.11-6.26
		c1.97-1.97,3.4-3.57,4.47-5c1.07-1.43,1.61-2.68,1.97-3.75c0.18-0.71,0.36-1.25,0.36-1.97s0-1.61-0.36-2.5
		c-0.18-0.71-0.36-1.25-0.72-1.97c-1.61,0.54-3.22,0-4.47-1.07l-1.43,1.07c-2.5,1.61-5.72,2.68-8.76,2.68
		c-1.25,0-2.5-0.18-3.75-0.54c-0.54-0.18-1.07-0.18-1.79-0.18c-0.89,0-1.79,0.18-2.68,0.36L12.52,26.8c0.36,0.89,1.07,1.79,1.97,2.5
		c1.25,1.07,2.86,1.79,4.47,1.97l5-3.04c1.07-0.71,2.5-0.36,3.22,0.72s0.36,2.5-0.72,3.22L12.7,41.1c0.71,0.54,1.43,1.25,2.32,1.61
		c1.43,0.71,2.86,1.07,4.47,1.07c1.61,0,3.04-0.36,4.65-1.25c1.43-0.71,2.68-1.79,3.75-3.04l1.61-2.14l0.54-0.72
		c0.72-0.89,2.14-1.07,3.22-0.36c0.89,0.72,1.07,2.15,0.36,3.22l-0.54,0.71l-1.61,2.14c-1.43,1.97-3.22,3.4-5.36,4.47
		c-1.25,0.54-2.5,1.07-3.75,1.25c0,1.07,0,2.32-0.18,3.4c-0.18,1.07-0.54,2.14-1.07,3.22c0,0.11-1.5,3.08-2.42,4.91h23.18
		L40.94,54.86z"
      />
      <path
        fill={lineColor}
        d="M56.67,21.26c-0.36-1.43-0.89-2.5-1.61-3.75l-2.5-4.11c-0.54-1.07-1.97-1.61-3.22-1.07l-0.18,0.18l-6.61,4.47
		c-1.79,1.25-3.93,1.97-6.08,1.97c-0.89,0-1.79-0.18-2.5-0.36c-0.89-0.18-1.97-0.36-3.04-0.36c-1.25,0-2.5,0.18-3.75,0.36
		L8.77,22.69c-1.25,0.36-1.97,1.61-1.79,2.86c0,0.18,0,0.36,0.18,0.54c0.72,2.68,2.14,5,4.29,6.79c0.71,0.71,1.61,1.25,2.5,1.61
		l-6.08,3.93c-1.07,0.72-1.43,2.14-0.72,3.22c1.43,2.15,3.4,3.93,5.72,5.18c1.43,0.72,3.04,1.25,4.65,1.43c0,0.72,0,1.43-0.18,1.97
		c-1.42,4.24-3.6,7.21-4.67,9.38h6c0.92-1.83,2.42-4.8,2.42-4.91c0.54-1.07,0.89-2.15,1.07-3.22c0.18-1.07,0.18-2.32,0.18-3.4
		c1.25-0.18,2.5-0.72,3.75-1.25c2.14-1.07,3.93-2.5,5.36-4.47l1.61-2.14l0.54-0.71c0.72-1.07,0.54-2.5-0.36-3.22
		c-1.07-0.71-2.5-0.54-3.22,0.36l-0.54,0.72l-1.61,2.14c-1.07,1.25-2.32,2.32-3.75,3.04c-1.61,0.89-3.04,1.25-4.65,1.25
		c-1.61,0-3.04-0.36-4.47-1.07c-0.89-0.36-1.61-1.07-2.32-1.61l13.76-8.94c1.07-0.71,1.43-2.14,0.72-3.22s-2.14-1.43-3.22-0.72
		l-5,3.04c-1.61-0.18-3.22-0.89-4.47-1.97c-0.89-0.72-1.61-1.61-1.97-2.5l15.91-3.57c0.89-0.18,1.79-0.36,2.68-0.36
		c0.71,0,1.25,0,1.79,0.18c1.25,0.36,2.5,0.54,3.75,0.54c3.04,0,6.26-1.07,8.76-2.68l1.43-1.07c1.25,1.07,2.86,1.61,4.47,1.07
		c0.36,0.72,0.54,1.25,0.72,1.97c0.36,0.89,0.36,1.79,0.36,2.5s-0.18,1.25-0.36,1.97c-0.36,1.07-0.89,2.32-1.97,3.75
		c-1.07,1.43-2.5,3.04-4.47,5c-1.97,1.97-3.4,4.11-4.11,6.26c-0.89,2.15-1.07,4.47-1.07,6.61c0,1.97,0.18,3.93,0.54,5.9l0.92,4.73
		h4.79l-1.06-5.63c-0.36-1.79-0.54-3.4-0.54-5c0-1.79,0.18-3.4,0.89-5c0.54-1.61,1.61-3.04,3.04-4.65c2.68-2.68,4.65-5,6.08-7.33
		c0.72-1.07,1.25-2.32,1.61-3.4c0.36-1.07,0.54-2.32,0.54-3.4C57.21,23.94,57.03,22.69,56.67,21.26z"
      />
    </g>
  </svg>
);
