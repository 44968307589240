import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export type HeaderBarState = {
  headerBarTitle: string;
  setHeaderBarTitle: (title: string) => void;
};

// useHeaderBarStore is a store that manages the state of the title bar (the top bar of the application)
const useHeaderBarStore = create(
  devtools<HeaderBarState>(
    (set) => ({
      headerBarTitle: '',
      setHeaderBarTitle: (headerBarTitle = '') => set({ headerBarTitle })
    }),
    {
      name: 'checkup-header-bar'
    }
  )
);

export const useHeaderBarTitle = () => useHeaderBarStore((state) => state.headerBarTitle);
export const useSetHeaderBarTitle = () => useHeaderBarStore((state) => state.setHeaderBarTitle);
