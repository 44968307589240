// getEnvironmentFromLocation is a utility function that returns the current environment based on the NODE_ENV
// environment variable and the URL.  If the NODE_ENV is 'development' then the environment is 'development'.  If the
// NODE_ENV variable is 'production', then the environment is determined by the subdomain of the URL (in the format of
// https://subDomain.environment.domain). If the environment is not part of the URL (e.g. https://subDomain.domain)
// then the environment is 'production'.
export const getEnvironmentFromLocation = (domain: string, subDomain: string) => {
  const envRegEx = new RegExp(`^${subDomain.replace('.', '\\.')}\.(.*\.)?${domain.replace('.', '\\.')}`, 'i');

  if (process.env.NODE_ENV === 'development') {
    return 'development';
  }

  if (process.env.NODE_ENV !== 'production') {
    return 'unknown';
  }

  if (process.env.NODE_ENV === 'production') {
    const matches = window.location.host.match(envRegEx);

    if (matches === null) {
      return 'unknown';
    }

    if (matches?.[1]) {
      // We found a subdomain, so we'll remove the dot and use that as the environment
      return matches[1].replace(/\./g, '');
    }
  }

  return 'production';
};
