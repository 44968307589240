import * as React from 'react';
import { Command as CommandPrimitive } from 'cmdk';
import { cn } from '@companion-professional/webutils';

export * from './CommandDialog';
export * from './CommandInput';
export * from './CommandList';
export * from './CommandEmpty';
export * from './CommandGroup';
export * from './CommandItem';
export * from './CommandShortcut';
export * from './CommandSeparator';

// Command (and its sub-components) are used to display a list of commands.  This is commonly used with the <Popover />
// component.  See story for a complete example.
//
// The common structure of the command is:
//   <Command>
//     <CommandInput>...</CommandInput>
//     <CommandList>
//       <CommandEmpty>...</CommandEmpty>
//       <CommandGroup>
//         <CommandItem>...</CommandItem>
//       </CommandGroup>
//       <CommandSeparator />
//     </CommandList>
//   </Command>
//
//  Under the hood, the <Command /> component uses the cmdk component library: https://githu.com/pacocoursey/cmdk
export const Command = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive>
>(({ className, ...props }, ref) => (
  <CommandPrimitive
    ref={ref}
    className={cn(
      'bg-popover text-popover-foreground flex h-full w-full flex-col overflow-hidden rounded-md',
      className
    )}
    {...props}
  />
));
Command.displayName = CommandPrimitive.displayName;
