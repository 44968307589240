import React, { FunctionComponent, useEffect } from 'react';
import * as Sentry from '@sentry/browser';
import { MuxUploader } from '@companion-professional/components';
import { VideoCaptureWrapper } from '../VideoCaptureWrapper';

interface UploaderProps {
  signedUploadUrl?: string | null;
  file: File;
  onReset: () => void;
  onUploadComplete: () => void;
  onError?: (error: CustomEvent<any>) => void;
}

// Uploader is a component that wraps the MuxUploader component with some minimal UI.  Once this component is mounted,
// it will automatically upload the file to Mux (using the signed upload URL).
//
// 10/01/2024 - We are currently using the UpChunk library to upload the video.  We changed some settings to try to
// make it more reliable, but if it's still not working, we may need to switch to a different library.  One potential
// library to try is https://github.com/rpldy/react-uploady
export const Uploader: FunctionComponent<UploaderProps> = ({
  file,
  onReset,
  signedUploadUrl,
  onUploadComplete,
  onError = () => {}
}) => {
  if (!signedUploadUrl) {
    return null;
  }

  useEffect(() => {
    Sentry.addBreadcrumb({
      category: 'checkup_flow',
      message: 'Uploading video'
    });
  }, []);

  return (
    <VideoCaptureWrapper>
      <div className="flex h-16 items-center justify-center text-xl">
        <div className="text-center font-bold">Uploading Video</div>
      </div>
      <div className="flex flex-1 flex-col items-center justify-center">
        <MuxUploader
          file={file}
          onReset={onReset}
          signedUploadUrl={signedUploadUrl}
          onUploadComplete={onUploadComplete}
          onAttemptFailure={(failure) => {
            Sentry.addBreadcrumb({
              category: 'checkup_flow',
              message:
                `Failed to upload chunk number: ${failure.detail.chunkNumber}. ` +
                `Attempts left: ${failure.detail.attemptsLeft}`,
              data: {
                chunk_number: failure.detail.chunkNumber,
                attempts_left: failure.detail.attemptsLeft
              },
              level: 'warning'
            });
          }}
          onError={(err) => {
            Sentry.withScope((scope) => {
              scope.setTags({
                chunk_number: err.detail.chunkNumber,
                attempts: err.detail.attempts
              });

              // Make sure we log this error to Sentry
              Sentry.captureException(err.detail.message);
              console.error('Error uploading video', err);
            });

            onError(err);
          }}
          // Manually setting the number of attempts to 6 and delay to 2 seconds.  This is so we can:
          //   1. Test if the settings are actually being applied.
          //   2. Allow for a bit more time to resolve a poor network connection (~12 seconds total as opposed to the
          //      ~5 seconds for the default).
          attempts={6}
          delayBeforeAttempt={2.0}
        />
      </div>
    </VideoCaptureWrapper>
  );
};
