import { useEffect, useRef, useState } from 'react';
import { CameraCaptureState, CameraCaptureStates } from './inBrowserCameraTypes';
import { DeviceList, getDevices, resetVideoStreams } from './utils';

// TODO: The console.error() should be removed or converted to logger (not yet implemented)

// TODO: Add limits to the recording time and/or the size of the video.

// From the MediaService docs (https://developer.mozilla.org/en-US/docs/Web/API/MediaRecorder/start#timeslice)
// MEDIA_RECORDER_TIMESLICE_MS is the number of milliseconds to record into each Blob (the mediaChunks ref in the
// component). This value is passed into the start() function of the MediaRecorder, and it's used for how often the
// ondataavailable event is fired.  Setting it to 2 seconds for now, but we might what to tweak this especially if
// we plan on caching the video slices into something like IndexedDB.
const MEDIA_RECORDER_TIMESLICE_MS = 2000;

// Codec options for the media recorder order by preference (most preferred first)
const PREFERRED_MIME_TYPES = [
  'video/webm;codecs=vp9',
  'video/webm;codecs=vp8,opus',
  'video/mp4;codecs=avc1',
  'video/webm'
];

export type VideoQualityPreferences = {
  width: { min?: number; ideal?: number; max?: number };
  height: { min?: number; ideal?: number; max?: number };
  framerate: { min?: number; ideal?: number; max?: number };
};

// Currently just setting the "ideal" settings to maximize support across devices.
const DEFAULT_VIDEO_QUALITY_PREFERENCES: VideoQualityPreferences = {
  width: { ideal: 1280 },
  height: { ideal: 720 },
  framerate: { ideal: 30 }
};

// useMediaRecorder is a custom hook that returns the video element and the media recorder object.  The preferences
// can be passed in to set the quality of the video, defaults to DEFAULT_VIDEO_QUALITY_PREFERENCES.
export const useMediaRecorder = (preferences = DEFAULT_VIDEO_QUALITY_PREFERENCES) => {
  const [error, setError] = useState('');
  const [cameraCaptureState, setCameraCaptureState] = useState<CameraCaptureState>(CameraCaptureStates.LOADING);
  const [recordingStartTime, setRecordingStartTime] = useState<number | null>(null);
  const [recordingStopTime, setRecordingStopTime] = useState<number | null>(null);
  const [devices, setDevices] = useState<DeviceList>({ video: [], audio: [] });
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const mediaChunksRef = useRef<Blob[]>([]);
  const finalBlobRef = useRef<Blob | null>(null);
  const streamRef = useRef<MediaStream | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);

  // _resetRecording is a helper function that resets the recording state of the component.
  const _resetRecording = () => {
    setRecordingStartTime(null);
    setRecordingStopTime(null);
    mediaChunksRef.current = [];
    finalBlobRef.current = null;
    streamRef.current = null;
  };

  // startCamera is a function that starts the camera with the given video and audio device ids.  If no video device id
  // is given, it will default to the environment facing camera (i.e. the back camera).
  const startCamera = async (videoDeviceId?: string, audioDeviceId?: string) => {
    resetVideoStreams(videoRef.current);
    _resetRecording();

    if (navigator.mediaDevices) {
      const videoConstraints: MediaStreamConstraints['video'] = preferences;
      if (videoDeviceId) {
        videoConstraints.deviceId = videoDeviceId;
      } else {
        // If no video device id was passed in, we will default to the environment facing camera (i.e. the back camera)
        videoConstraints.facingMode = 'environment';
      }

      const audioConstraints: MediaStreamConstraints['audio'] = audioDeviceId ? { deviceId: audioDeviceId } : true;
      const constraints: MediaStreamConstraints = { video: videoConstraints, audio: audioConstraints };

      try {
        const stream = await navigator.mediaDevices.getUserMedia(constraints);

        if (videoRef.current !== null) {
          videoRef.current.srcObject = stream;
          videoRef.current.controls = false;
          streamRef.current = stream;

          // After the stream is set up, we can get the devices.  It seems to be important to get the devices after the
          // stream is set up for various browsers (e.g. safari running on iOS).
          setDevices(await getDevices());

          if (devices.video.length === 0 || devices.audio.length === 0) {
            // NOTE: We have to call getDevices() twice because of firefox. The first time (above) we getDevices() in
            // firefox the device.label is an empty string.  After getUserMedia() happens successfully, then we can
            // getDevices() again to and the 2nd time then device.label will be populated.
            setDevices(await getDevices());
          }
        } else {
          setError("This browser doesn't support video capture. Please try a different browser.");
        }
      } catch (e) {
        console.error('Error creating stream', e);
        setError('Unable to start camera');
        return;
      }
    } else {
      console.error('Browser does not support media devices');
      setError("This browser doesn't support video capture. Please try a different browser.");
      return;
    }

    setCameraCaptureState(CameraCaptureStates.LIVE);
  };

  // stopCamera is a function that stops the camera and resets the video streams.
  const stopCamera = () => {
    resetVideoStreams(videoRef.current);
    setCameraCaptureState(CameraCaptureStates.LOADING);
  };

  // startRecording is a function that starts recording video from the camera.  It will record the video into a blob
  // in memory.
  const startRecording = async () => {
    if (cameraCaptureState === CameraCaptureStates.REVIEWING) {
      _resetRecording();
      await startCamera();
    }

    if (cameraCaptureState !== CameraCaptureStates.LIVE) {
      console.error('Cannot start recording when camera is not live');
      setError('Unable to start recording');
      return;
    }

    if (videoRef.current === null) {
      console.error('Cannot start recording without a video element');
      setError('Unable to start recording');
      return;
    }

    try {
      const options: MediaRecorderOptions = {};
      for (const mimeType of PREFERRED_MIME_TYPES) {
        // NOTE: MediaRecorder.isTypeSupported does not exist in safari
        if (MediaRecorder.isTypeSupported(mimeType)) {
          options.mimeType = mimeType;
          break;
        }
      }
      if (!options?.mimeType) {
        console.error('No supported mime types found, tried:', PREFERRED_MIME_TYPES);
        setError('Unable to start recording');
        return;
      }

      const stream = streamRef.current;
      if (!stream) {
        console.error('Cannot record without a stream');
        setError('Unable to start recording');
        return;
      }

      const recorder = new MediaRecorder(stream, options);
      mediaRecorderRef.current = recorder;

      recorder.ondataavailable = (evt) => {
        mediaChunksRef.current.push(evt.data);
      };

      recorder.onstop = () => {
        finalBlobRef.current = new Blob(mediaChunksRef.current, { type: recorder.mimeType });
        const objUrl = URL.createObjectURL(finalBlobRef.current);

        const video = videoRef.current;
        if (video !== null) {
          video.srcObject = null;
          video.src = objUrl;
          video.controls = true;
          video.muted = true;
          setCameraCaptureState(CameraCaptureStates.REVIEWING);
          mediaRecorderRef.current = null;
        }

        setRecordingStopTime(new Date().valueOf());
      };

      recorder.onstart = () => {
        setRecordingStartTime(new Date().valueOf());
      };

      recorder.start(MEDIA_RECORDER_TIMESLICE_MS);
      setCameraCaptureState(CameraCaptureStates.RECORDING);
    } catch (e) {
      console.error('Error starting recording', e);
      setError('Error starting recording');
      return;
    }
  };

  // stopRecording is a function that stops the recording of the video.
  const stopRecording = () => {
    if (mediaRecorderRef.current === null) {
      console.error('Cannot stop recording without a recorder');
      setError('Error stopping recording');
      return;
    }

    mediaRecorderRef.current.stop();
    resetVideoStreams(videoRef.current);
    setCameraCaptureState(CameraCaptureStates.REVIEWING);
  };

  // The following cleans up the video stream when the component is unmounted.  This should ensure that the camera is
  // released (e.g. the camera light turns off on the device).
  useEffect(() => {
    const video = videoRef.current;
    return function () {
      resetVideoStreams(video);
    };
  }, []);

  return {
    error,
    videoRef,
    finalBlobRef,
    mediaRecorderRef,
    cameraCaptureState,
    recordingStartTime,
    recordingStopTime,
    devices,
    startCamera,
    startRecording,
    stopRecording,
    stopCamera
  };
};
