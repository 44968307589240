import * as React from 'react';
import { Label } from '../Label';
import { Input } from '../Input';

interface AdditionalProps {
  label?: string;
  description?: string;
  children?: React.ReactNode;
}

export interface InputWithLabelProps extends React.InputHTMLAttributes<HTMLInputElement>, AdditionalProps {}

// InputWithLabel is a component that wraps the Input component and adds a label.  The children prop can be passed in
// to add additional contents inside the div (e.g. error message).
export const InputWithLabel = React.forwardRef<HTMLInputElement, InputWithLabelProps>(
  ({ label, children, description, ...props }, ref) => {
    return (
      <div className="mb-2 flex w-full flex-col gap-y-2">
        {label ? (
          <Label htmlFor={props.id} description={description}>
            {label}
          </Label>
        ) : null}
        <Input ref={ref} {...props} />
        {children}
      </div>
    );
  }
);
InputWithLabel.displayName = 'InputWithLabel';
