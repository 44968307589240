import * as React from 'react';
import * as SelectPrimitive from '@radix-ui/react-select';
import { cn } from '@companion-professional/webutils';

// SelectSeparator is a component that displays a separator (a line) in a Select component.
export const SelectSeparator = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Separator ref={ref} className={cn('bg-muted -mx-1 my-1 h-px', className)} {...props} />
));
SelectSeparator.displayName = SelectPrimitive.Separator.displayName;
