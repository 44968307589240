import * as React from 'react';
import { Command as CommandPrimitive } from 'cmdk';

// CommandEmpty is used to display a message when there are no commands to display.   This can happen when the
// CommandInput is used and there are no matches.
export const CommandEmpty = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Empty>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Empty>
>((props, ref) => <CommandPrimitive.Empty ref={ref} className="py-6 text-center text-sm" {...props} />);

CommandEmpty.displayName = CommandPrimitive.Empty.displayName;
