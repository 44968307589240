import React, { FunctionComponent, useEffect } from 'react';
import { SplitLayout } from '../../components/SplitLayout';
import { LanderContent } from './LanderContent';
import { CheckupRequestInfo } from '@companion-professional/dctypes';
import { useSetHeaderBarTitle } from '../../state/headerBar';
import { trackEvent } from '../../lib/matomo';

interface CheckupLanderProps {
  checkupRequest: CheckupRequestInfo;
}

// CheckupLander is the first page that is shown to the Pet Parent when they click on the link to the checkup.  It
// contains a greeting and action button(s) for starting the checkup.
export const CheckupLander: FunctionComponent<CheckupLanderProps> = ({ checkupRequest }) => {
  const setHeaderBarTitle = useSetHeaderBarTitle();

  useEffect(() => {
    setHeaderBarTitle(`Checkup for ${checkupRequest?.pet_name || 'your pet'}`);
    trackEvent('checkup', 'checkup_flow_change', 'checkup_lander');
  }, [checkupRequest?.pet_name]);

  return (
    <SplitLayout>
      <LanderContent checkupRequest={checkupRequest} />
    </SplitLayout>
  );
};
