import React, { FunctionComponent } from 'react';
import { CameraIcon } from 'lucide-react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger
} from '../DropdownMenu';
import { DeviceList } from './utils';

interface DeviceSettingsProps {
  // devices is the list of audio and video devices that are available to the user.
  devices: DeviceList;

  // currentVideoDeviceId is the currently selected video device.  If no video device is selected, this should be
  // undefined.
  currentVideoDeviceId?: string;

  // onVideoDeviceIdChange is a function that is called when the user selects a new video device.  It is passed the
  // deviceId of the new video device.
  onVideoDeviceIdChange: (deviceId: string | undefined) => void;
}

// DeviceSettings is a component that allows the user to select the video and audio devices that they want to use.
export const DeviceSettings: FunctionComponent<DeviceSettingsProps> = ({
  devices,
  currentVideoDeviceId,
  onVideoDeviceIdChange
}) => {
  // TODO: Add audio device selection.  Starting out with just video device selection.

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <CameraIcon color={'white'} size={30} />
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuLabel>Change Camera</DropdownMenuLabel>
        <DropdownMenuRadioGroup onValueChange={onVideoDeviceIdChange} value={currentVideoDeviceId || undefined}>
          {devices.video.map(({ label, deviceId }) => (
            <DropdownMenuRadioItem value={deviceId} key={deviceId}>
              <span>{label}</span>
            </DropdownMenuRadioItem>
          ))}
        </DropdownMenuRadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
