import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { intervalToDuration } from 'date-fns';

// UPDATE_INTERVAL_MS is the interval at which the timer is updated.  Currently set to update every second.
const UPDATE_INTERVAL_MS = 1000;

interface VideoTimeProps {
  // recordingStartTime is the time in milliseconds when the recording started.  If the recording hasn't started yet,
  // this should be null.
  recordingStartTime: number | null;
}

// VideoTime is responsible for rendering the current time (ie duration) of the recording.
export const VideoTime: FunctionComponent<VideoTimeProps> = ({ recordingStartTime }) => {
  const [currentTime, setCurrentTime] = useState<string>('00:00');

  const setTime = useCallback(
    (start: number, end: number) => {
      const { minutes, seconds } = intervalToDuration({ start, end });
      setCurrentTime(`${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`);
    },
    [setCurrentTime]
  );

  useEffect(() => {
    if (recordingStartTime === null) {
      return;
    }

    const interval = setInterval(() => {
      setTime(recordingStartTime, new Date().valueOf());
    }, UPDATE_INTERVAL_MS);

    return () => clearInterval(interval);
  }, [recordingStartTime]);

  // TODO: the time text should be mono-spaced so that the layout doesn't jump around as the time changes.
  return <div className="text-2xl font-bold text-white">{currentTime}</div>;
};
