import * as React from 'react';
import { cn } from '@companion-professional/webutils';

interface TableCellProps extends React.TdHTMLAttributes<HTMLTableCellElement> {
  // hideWhenMd is used to hide the column when the screen size is medium or smaller.
  hideWhenMd?: boolean;
}

export const TableCell = React.forwardRef<HTMLTableCellElement, TableCellProps>(
  ({ hideWhenMd = false, className, ...props }, ref) => (
    <td
      ref={ref}
      className={cn(
        'px-2 py-1 align-middle [&:has([role=checkbox])]:pr-0 [&:has([role=checkbox])]:pt-2',
        {
          'hidden md:table-cell': hideWhenMd
        },
        className
      )}
      {...props}
    />
  )
);
TableCell.displayName = 'TableCell';
