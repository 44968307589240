import React, { FunctionComponent } from 'react';

interface RecordButtonProps {
  // size is the size of the button in pixels.
  size?: number;

  // buttonLook is for configuring what the button looks like.
  // - 'record' shows a round record icon
  // - 'stop' shows a square stop icon
  buttonLook?: 'record' | 'stop';

  // onClick is a function that is called when the button is clicked.
  onClick?: () => void;
}

// RecordButton is a custom SVG button that is designed to look like a mobile OS camera video record button.  The
// button can be in one of two 'buttonLook' option which alter what the button looks like: 'record' or 'stop'.
export const RecordButton: FunctionComponent<RecordButtonProps> = ({
  size = 38,
  buttonLook = 'record',
  onClick = () => {}
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 124 124`}
      width={size}
      height={size}
      onClick={onClick}
      className="cursor-pointer"
    >
      <circle
        cx={62}
        cy={62}
        r={56}
        style={{
          strokeMiterlimit: 10,
          strokeWidth: 8,
          fill: 'none',
          stroke: '#f1f2f2'
        }}
      />
      {buttonLook === 'record' ? (
        <circle
          cx={62}
          cy={62}
          r={44}
          style={{
            fill: '#ed1c24',
            stroke: '#be1e2d',
            strokeMiterlimit: 10,
            strokeWidth: 4
          }}
        />
      ) : (
        <rect
          x={42}
          y={40}
          width={44}
          height={44}
          style={{
            fill: '#ed1c24'
          }}
        />
      )}
    </svg>
  );
};
