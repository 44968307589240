import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { z } from 'zod';
import { RadioValue, RadioWithOtherValue } from '../DynamicForm/dynamicFormSchema';
import { RadioGroupItemWithLabel, RadioGroupWithLabel } from '../RadioGroup';
import { Input } from '../Input';

export const RadioOptionSchema = z.object({
  label: z.string(),
  value: z.string().or(z.number())
});
export type RadioOption = z.infer<typeof RadioOptionSchema>;

interface RadioGroupWithOtherProps {
  // name is the name of the radio group.  This is used for the name attribute of the radio input elements and the
  // parts of id attributes of various elements.
  name: string;

  // label is the text that will be displayed above the radio group.  If both label and description are not provided,
  // the label and description section will not be rendered.
  label?: string;

  // description is the text that will be displayed below the label. If both label and description are not provided,
  // the label and description section will not be rendered.
  description?: string;

  // options is an array of objects that represent the radio options.  Each object must have a label and a value.
  options: RadioOption[];

  // onChange is a function that will be called when the value of the radio group or the other text field changes.
  onChange: (value: RadioValue) => void;

  // value is the current value of the radio group.
  value?: string;

  // other is the current value of the "other" input field.
  other?: string;

  // defaultValue is the default value of the radio group.  This is used to set the initial value of the radio group.
  defaultValue?: string;

  // otherOption is an optional object that represents the "other" option.  If this is provided, the radio group will
  // have a radio value and an "other" input value.
  otherOption?: RadioOption;

  // otherOptionInputName is the name of the other input field.  This is used for the name attribute of the input
  // element.  If this is not provided, the name of the other input field will be "other".
  otherOptionInputName?: string;

  // otherOptionPlaceholder is the placeholder text that will be displayed in the other input field.
  otherOptionPlaceholder?: string;

  // disabled is a boolean that determines whether the radio group is disabled.
  disabled?: boolean;

  // required is a boolean that determines whether the radio group is required (i.e. if an option should be selected).
  required?: boolean;
}

// getValueFromRadioValue is a helper function that takes a value and returns the value of the radio group.
export const getValueFromRadioValue = (value: unknown): string => {
  if (typeof value !== 'object' && value !== null) {
    return String(value);
  }

  return String((value as RadioWithOtherValue)?.value);
};

// getOtherFromRadioValue is a helper function that takes a value and returns the value of the "other" input field.
export const getOtherFromRadioValue = (value: unknown, otherName = 'other'): string => {
  if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
    return String((value as RadioWithOtherValue)?.[otherName]) || '';
  }

  return '';
};

export const RadioGroupWithOther: FunctionComponent<RadioGroupWithOtherProps> = ({
  value = '',
  other = '',
  name,
  label,
  description,
  defaultValue,
  options,
  onChange,
  otherOption,
  otherOptionInputName,
  otherOptionPlaceholder,
  disabled,
  required
}) => {
  const otherInputName = otherOptionInputName || 'other';
  const [selectedValue, setSelectedValue] = useState<string>('');
  const [otherValue, setOtherValue] = useState<string>('');

  useEffect(() => {
    // Make sure that the value is set to the correct shape when the component is first rendered.
    setSelectedValue(value || '');
    setOtherValue(other || '');
  }, [value, other]);

  useEffect(() => {
    // TODO: This (the if not disabled part) is very much a hack to prevent re-renders (that only seem to  happen
    //  when loading answers into the form). A better, long term solution should be implemented.  Maybe some kind of
    //  state manager (like zustand).
    if (!disabled) {
      // This does the actual onChange call when the selectedValue or otherValue changes (i.e. updates the parent
      // component).
      if (otherOption) {
        onChange({ value: selectedValue || '', [otherInputName]: otherValue || '' });
      } else {
        onChange(selectedValue || '');
      }
    }
  }, [selectedValue, otherValue]);

  const onRadioValueChange = useCallback(
    (radioSelectedValue: string) => {
      if (otherOption) {
        // If the otherOption is provided, this component will have a radio value and an "other" input value.
        setSelectedValue(radioSelectedValue);
        setOtherValue('');
      } else {
        setSelectedValue(radioSelectedValue);
      }
    },
    [value]
  );

  const onOtherInputChange = useCallback(
    (otherValue: string) => {
      setOtherValue(otherValue);
    },
    [otherValue]
  );

  return (
    <RadioGroupWithLabel
      name={name}
      value={selectedValue || ''}
      defaultValue={defaultValue}
      label={label}
      description={description}
      onValueChange={onRadioValueChange}
      required={required}
      disabled={disabled}
    >
      {options?.map((option) => (
        <RadioGroupItemWithLabel
          id={`option-${name}-${option.value}`}
          key={`option-${name}-${option.value}`}
          label={option.label}
          value={String(option.value)}
          data-testid={`${name}-${option.value}`}
        />
      ))}

      {otherOption && (
        <div className="flex min-h-10 flex-col justify-center gap-x-2">
          <RadioGroupItemWithLabel value={String(otherOption.value)} label={otherOption.label} />
          <Input
            name={otherOptionInputName}
            disabled={selectedValue !== otherOption.value || disabled}
            value={otherValue || ''}
            onChange={(e) => {
              onOtherInputChange(e.target.value);
            }}
            placeholder={otherOptionPlaceholder}
            className="ml-6 focus-visible:outline-none focus-visible:ring-0"
          />
        </div>
      )}
    </RadioGroupWithLabel>
  );
};
