import React, { FunctionComponent } from 'react';
import { useHeaderBarTitle } from '../../state/headerBar';
import { cn } from '@companion-professional/webutils';

interface HeaderBarProps {
  hidden?: boolean;
}

export const HeaderBar: FunctionComponent<HeaderBarProps> = ({ hidden = false }) => {
  const headerBarTitle = useHeaderBarTitle();
  return (
    <div
      className={cn(
        'w-full border-b-2 border-widget-foreground bg-primary py-2 text-center text-base font-medium ' +
          'text-primary-foreground md:text-xl',
        {
          hidden: hidden
        }
      )}
    >
      {headerBarTitle}
    </div>
  );
};
