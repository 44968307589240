import { useEffect } from 'react';
import { LDContext, useLDClient } from 'launchdarkly-react-client-sdk';

// This hook will set the LaunchDarkly context based on the clinic id. This will allow us to target feature flags to
// checkups received from specific clinics.
export const useSetFeatureFlagContext = (clinicId: string) => {
  const ldClient = useLDClient();

  useEffect(() => {
    if (ldClient) {
      // Create a context with the returned clinic id.
      const context: LDContext = {
        kind: 'multi',
        clinic: {
          key: clinicId
        }
      };

      console.log('Setting user context with LD', context);
      ldClient.identify(context).catch((err) => {
        console.log('Error setting user context with LD', err);
      });
    }
  }, [ldClient]);
};
