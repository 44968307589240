// This is a central place to store configuration details for the application.  Currently, these are hardcoded, but in
// the future we could these could be dependent on the environment that the application is running in.

// API URLS
const API_BASE_URL = '/api/v1';
export const CHECKUP_REQUEST_URL = `${API_BASE_URL}/checkup_request`;
export const GENERATE_UPLOAD_URL = `${API_BASE_URL}/upload_url`;
export const GET_CLINIC_BY_SLUG_URL = `${API_BASE_URL}/clinic_by_slug`;
export const CREATE_CHECKUP_FOR_CLINIC_PET_URL = `${API_BASE_URL}/checkup_for_clinic_pet_id`;

// Support Contact Information
export const CONTACT_EMAIL = 'cv-support@companionprofessional.com';
export const CONTACT_PHONE = '+18774473647';
