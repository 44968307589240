import React, { FunctionComponent, useEffect } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { InBrowserCamera, VideoPlayer, VideoRecordingStats } from '@companion-professional/components';
import { companionProBranding } from '@companion-professional/branding';
import { FeatureFlags } from '../../types/featureFlags';
import { InBrowserCaptureButtons } from './InBrowserCaptureButtons';
import { NativeCameraCaptureButtons } from './NativeCameraCaptureButtons';
import { useCameraPreviewEnabled, useSetCameraPreviewEnabled } from '../../state/camera';
import { useSetHeaderBarTitle } from '../../state/headerBar';
import { getOSName } from '../../lib/ua';
import { trackEvent } from '../../lib/matomo';

interface CaptureWithVideoInstructionsProps {
  // instructionVideoMuxPlaybackId is the Mux playback ID for the video that will be displayed as instructions.
  instructionVideoMuxPlaybackId: string;

  // instructionVideoPoster is the URL to the poster image for the instruction video.
  instructionVideoPoster?: string;

  // onVideoSelected is a callback that is called when the user selects a video file.
  onVideoSelected?: (file: File, stats?: VideoRecordingStats) => void;
}

// CaptureWithVideoInstructions is a component that displays instructions (text and video) for how to properly capture
// a video.  It also provides a way for the user to capture a video (either in-browser capture or the native camera
// app).  The in-browser capture is only shown if the feature flag 'showInBrowserCameraCapture' is enabled.
export const CaptureWithVideoInstructions: FunctionComponent<CaptureWithVideoInstructionsProps> = ({
  instructionVideoMuxPlaybackId,
  onVideoSelected = () => {},
  instructionVideoPoster = ''
}) => {
  const flags = useFlags<FeatureFlags>();
  const setCameraPreviewEnabled = useSetCameraPreviewEnabled();
  const cameraPreviewEnabled = useCameraPreviewEnabled();
  const setHeaderBarTitle = useSetHeaderBarTitle();

  useEffect(() => {
    setHeaderBarTitle('Instructions');
    trackEvent('checkup', 'checkup_flow_change', 'instructions');
  }, []);

  if (cameraPreviewEnabled) {
    return (
      <div className="flex w-full flex-1 flex-col items-center justify-between">
        <InBrowserCamera
          onUploadVideo={onVideoSelected}
          onError={(error) => {
            console.error('Error recording video', error);
            setCameraPreviewEnabled(false);
          }}
          onBack={() => {
            setCameraPreviewEnabled(false);
          }}
        />
      </div>
    );
  }

  return (
    <div className="m-4 flex w-full max-w-xl flex-1 flex-col items-center justify-between gap-4">
      <div>
        <h2 className="px-2 text-sm font-medium text-widget-foreground sm:text-base">
          Walk the dog left to right, then front to back. Next ask your dog to sit, down, then stand up.
        </h2>
        <div className="my-2 space-y-2 px-2 text-sm text-widget-foreground/80 sm:text-base">
          <div className="text-left">Before you start:</div>
          <ul className="standard-list">
            <li>Prop the camera at the dog's height</li>
            <li>Put away all other dogs and animals</li>
            <li>Take off any harnesses or cones that obscure the dog's body</li>
            <li>Never use your hands on the dog during the video - skip the sit or down if they don't know it</li>
          </ul>
        </div>
      </div>
      <div className="flex w-full items-center justify-center">
        <div className="aspect-[4/3] w-full max-w-[500px]">
          <VideoPlayer
            muxPlaybackId={instructionVideoMuxPlaybackId}
            poster={instructionVideoPoster}
            primaryColor={companionProBranding.logoBackgroundColor}
            accentColor={companionProBranding.logoColor}
            muted={false}
            onPlay={() => {
              trackEvent('checkup', 'checkup_flow_change', 'instructions_video_play');
            }}
            onEnded={() => {
              trackEvent('checkup', 'checkup_flow_change', 'instructions_video_ended');
            }}
          />
        </div>
      </div>
      <div className="w-full flex-1 px-4">
        {/* Only show the in-browser capture buttons if the feature flag is enabled and if the OS is not Android (we
            had some issues with Android and screen rotation messing up the In-Browser capture). We fall back to native
            camera capture if the feature flag is not enabled */}
        {flags?.showInBrowserCameraCapture && getOSName() != 'Android' ? (
          <InBrowserCaptureButtons onVideoSelected={onVideoSelected} />
        ) : (
          <NativeCameraCaptureButtons onVideoSelected={onVideoSelected} />
        )}
      </div>
    </div>
  );
};
