// Helper TS function for getting the values of an object
type ObjectValues<T> = T[keyof T];

export const CameraCaptureStates = {
  // LOADING is the initial state of the camera capture.  The camera capture is loading the camera and preparing to
  // start capturing video.
  LOADING: 'LOADING',

  // LIVE is for when the camera is capturing live video that is being displayed (assuming the videoRef
  //   // is being used to display the video) but not saved anywhere.
  LIVE: 'LIVE',

  // RECORDING is for when the camera is capturing video and storing it (e.g. to a blob in memory).
  RECORDING: 'RECORDING',

  // REVIEWING is for when the camera is capturing video and storing it (e.g. to a blob in memory) and the user is
  REVIEWING: 'REVIEWING'
};
export type CameraCaptureState = ObjectValues<typeof CameraCaptureStates>;

export type VideoRecordingStats = {
  size: number;
  type: string;
  duration: number;
  width: number | undefined;
  height: number | undefined;
};
